
@use "../mixin" as *;
@use "../base" as *;
.close_icon {
    position: absolute;
    right: 15px;
    width: 14px;
    height: 14px;
    overflow: hidden;
    cursor: pointer;
    top: 10px;
    z-index: 5;
    right: 10px;
}
.close_icon.close_icon_sm {
    height: 10px;
    width: 10px;
}
.close_icon.close_icon_md {
    height: 15px;
    width: 15px;
}
.close_icon.close_icon_lg {
    height: 20px;
    width: 20px;
}
.close_icon::before, .close_icon::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #000;
    height: 1px;
}
.close_icon::before {
    transform: rotate(45deg);
}
.close_icon::after {
    transform: rotate(-45deg);
}