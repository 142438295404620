@use "../base" as *;
@use "../mixin" as *;
.item_list_wrapper {
    .tabs {
        li {
            button {
                position: relative;
                font-size: 11px;
            }
        }
    }
    .item_list_custom_tab {
        position: relative;
        .right {
            position: absolute;
            right: 0;
            top: -12px;
        }
    }
    .item_list_wrap {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
        padding-top: 20px;
        .item_list_inner {
            width: 14.2857%;
            padding-left: 10px;
            padding-right: 10px;
            padding-bottom: 35px;
            &.active {
                .item_list_inner_wrap {
                    border: 1.5px solid $color_white;
                }
            }
            .item_list_inner_wrap {
                display: flex;
                border: 1px solid $border_color;
                flex-direction: column;
                padding: 6px 10px;
                // border: 1.5px solid transparent;
                height: 100%;
                .list_icon {
                    @include d_flex_btwn;
                    border-bottom: 1px solid $border_color;
                    margin-bottom: 5px;
                    p {
                        margin-bottom: 0;
                        font-weight: 500;
                        font-size: 16px;
                    }
                    ul {
                        display: flex;
                        align-items: center;
                        @include d_flex_center;
                        li {
                            padding: 4px 0px;
                            padding-left: 10px;
                            @include d_flex_center;
                            img {
                                width: 11px;
                            }
                            span {
                                font-size: 12px;
                            }
                        }
                    }
                }
                .img {
                    position: relative;
                    width: 100%;
                    // padding-top: 145%;
                    .left_box {
                        position: relative;
                        left: 0;
                        top: 0;
                        // height: 100%;
                        width: 100%;
                        background: rgba(0,0,0,.6);
                        background: linear-gradient(77deg,rgba(0,0,0,.6),transparent 85%);
                    }
                    .form_checkbox [type="checkbox"] + label:before, 
                    .form_checkbox .form_checkbox[type="checkbox"]:not(.filled-in) + label:after {
                        // content: '';
                        // position: absolute;
                        // top: -3px;
                        // left: 0;
                        // width: 15px;
                        // height: 15px;
                        // z-index: 0;
                        // border: 2px solid rgba(255,255,255,.8);
                        // border-radius: 1px;
                        margin-top: 0px;
                        // transition: .2s;
                    }
                    .form_checkbox [type="checkbox"]:checked + label:before {
                        top: -2px;
                        left: -4px;
                        // width: 10px;
                        // height: 16px;
                        // border-top: 2px solid transparent;
                        // border-left: 2px solid transparent;
                        // border-right: 2px solid rgba(255,255,255,.8);
                        // border-bottom: 2px solid rgba(255,255,255,.8);
                    }
                    .form_checkbox {
                        position: absolute;
                        top: 6px;
                        left: 6px;
                        padding: 0;
                        label {
                            padding-left: 8px;
                            background: #fff;
                            min-height: 18px;
                        }
                    }
                    a {
                        display: flex;
                    }
                    img {
                        width: 100%;
                    }
                }
                .text {
                    // padding: 10px;
                    // flex: 1;
                    // border: 1px solid $border_color_type2;
                    // display: flex;
                    // flex-direction: column;
                    // align-items: center;
                    // justify-content: center;
                    position: relative;
                    padding-top: 10px;
                    h3 {
                        background: $color_black;
                        margin-bottom: 10px;
                        font-weight: 500;
                        line-height: 16px;
                        font-size: 13px;
                        display: inline-flex;
                        padding: 3px 10px;
                        border-radius: 3px;
                        color: #fff;
                    }
                    p {
                        margin-bottom: 5px;
                        // font-size: 13px;
                    }
                    .date {
                        font-size: 15px;
                        &.date_normal {
                            color: #818181;
                        }
                    }
                    .style_no {
                        font-size: 15px;
                    }
                    .price {
                        display: inline-flex;
                        padding: 3px 0px;
                        // border-radius: 3px;
                        // color: #fff;
                        font-size: 15px;
                        border-bottom: 1px solid $border_color_type2;
                        padding-bottom: 5px;
                        margin-bottom: 5px;
                        width: 100%;
                    }
                    .preorder {
                        span {
                            background: #464646;
                            display: inline-flex;
                            padding: 3px 4px;
                            border-radius: 3px;
                            color: #fff;
                            font-size: 12px;
                            margin-bottom: 3px;
                        }
                    }
                }
            }
        }
    }
}


// .item_color_list {
//     .item_color_list_inner {
//         .inner_wrap {
//             h2 {}
//             p {}
//             .innner_content {
//                 .innner_content_wrap {
//                     p {

//                     }
//                 }
//             }
//         }
//     }
// }