@mixin d_flex_start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
@mixin d_flex_center {
    display: flex;
    justify-content: center;
    align-items: center;
}
@mixin d_flex_btwn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@mixin d_flex_end {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.flex_align_top {
    align-items: flex-start!important;
}
.d_flex_start {
    @include d_flex_start;
}
.d_flex_center {
    @include d_flex_center;
}
.d_flex_btwn {
    @include d_flex_btwn;
}
.d_flex_end {
    @include d_flex_end;
}
.d_inline_flex {
    display: inline-flex;
    flex-wrap: wrap;
}
.center_horizontally {
    align-items: center;
}
.show {
    display: block;
}
.hide {
    display: none;
}