

@use "../base" as *;
.accordion {
    margin-bottom: 10px;
    .accordion_heading {
        .accordion_heading_inner {
            // @include d_flex_center;
            padding: 10px 15px;
            // background: $dropdown_bg;
            border: 1px solid $border_color_type2;
            cursor: pointer;
            position: relative;
            font-size: 15px;
            line-height: 15px;
            &.left {
                padding-left: 35px;
            }
            &:before {
                font-family: 'LineIcons' !important;
                position: absolute;
                content: "\eb11";
                transition: all .3s;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 13px;
            } 
            &.active:before {
                transition: all .3s;
                content: "\eb0b";
            }
            &.left:before {
                transition: all .3s;
                right: auto;
                left: 10px;
            }
        }
    }
    .accordion_body {
        padding: 15px;
        border-style: solid;
        border-color: $border_color_type2;
        border-width: 0px 1px 1px 1px;
        display: none;
        &.active {
            display: block;
        }
    }
}