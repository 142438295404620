@use "base" as *;
.w_maxContent{
  width: max-content !important;
}

.text_center{
  text-align: center;
}

.text_muted {
  color: grey;
}

.react-confirm-alert-overlay {
  z-index: 9999999 !important;
}

select.form_global{
  cursor: pointer;
}

.d_block{
  display: block;
}

.sub_accordion.first_level{
  //max-height: 0;
  //transition: max-height 0.30s ease-out;
  //overflow: hidden;
  //display: block;
}

.sub_accordion.first_level.current{
  // height: max-content;
  //transition: height 0.30s ease-in;
}

.reactive-btn{
  min-width: 68px;
  border: 0px !important;
  opacity: 1 !important;
  padding: 6px 12px !important;
}
.left_f_list{
  &.open{
    span {
      font-weight: bold;
    }
  }
}
.sub_accordion {
  li{
    a{
      &.active{
        background: transparent;
        color: #000 !important;
        font-weight: bold;
      }
    }
  }
}
.border_radius_2px{
  border-radius: 2px;
}


.item_color_main_wrap {
  .item_color_list_wrap {
    .item_color_wrap {
      display: flex;
      flex-wrap: wrap;
      padding: 25px 0;
      margin-left: -5px;
      margin-right: -5px;
      .item_color_inner {
        width: 12.5%;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 10px;
        .item_color_inner_wrap {
          padding: 10px;
          display: flex;
          border: 1px solid #dfe0e1;
          .image_div {
            //height: 100%;
            width: 34px;
          }
          .item_color_text {
            padding-left: 5px;
          }
        }
      }
    }
  }
}
.react-confirm-alert-button-group{
  display: flex !important;
  justify-content: space-between !important;
  button:first-child{
    background: #FFDFDF;
    border-color: #FFDFDF;
    color: #E24E4D;
  }
}
.order_add_product_sku{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit;
}

.form_global {
  &.multi_select {
    padding: 0px;
    .css-13cymwt-control {
      border: 0px;
      border-radius: 2px;
      min-height: 30px;
      height: 40px;
      margin-top: -2px;
      background: transparent;
      outline: none !important;
      &:focus, &:active{
        outline: none !important;

      }
    }
  }
}

.add_category_btn{
  position: absolute;
  right: 0;
  top: 0;
}

.imageWrapper{

  border: 1px solid #dfe0e1;
  .images_outer{
    .image_item{
      margin: 10px;
      width: 8.66%;
      img{
        width: 100%;
      }
    }
  }
}
.p_relative{
  position: relative;
}
.t_0{
  top: 0;
}
.r_0{
  right: 0;
}
.b_0{
  bottom: 0;
}

.p_absolute{
  position: absolute;
}


.create_new_item_color {
  .color_filter_content {
    padding-top: 10px;
    .search_input {
      position: relative;
      width: 100%;
      input {
        padding-left: 40px;
      }
    }
  }
  .color_item_list {
    display: flex;
    flex-wrap: wrap;
    .color_item_list_inner {
      width: 15%;
      padding: 5px;
      margin-bottom: 5px;
      .form_checkbox {
        border: 1px solid #f4f5fa;
        padding: 5px;
        width: 100%;
      }
    }
  }
}

.create_inventory_area{

}


#preloader {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1050;
}
body #preloader {
  background-color: hsla(0, 0%, 100%, 0.8);
}
#preloader_inner {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 75px;
  justify-content: space-between;
  left: 50%;
  position: absolute;
  top: calc(50% - 80px);
  transform: translate(-50%, -50%);
  width: 120px;
}
#preloader_inner h1 {
  color: #fff;
  font-family: sans-serif;
  font-size: 1.2rem;
  font-weight: 100;
  margin-bottom: 0;
  text-align: center;
}
#preloader_inner h1 img {
  width: 100px;
}

.left_nav_list {
  li {
    .left_f_list {
      .active {
        background: transparent !important;
        color: #000 !important;
      }
    }
  }
}

.m_height_400p{
  min-height: 400px;
}
.m_height_200p{
  min-height: 200px;
}
.height_40p{
  height: 40px;
}

.table.table_bordered{
  tbody{
    tr{
      td{
        &:last-child{
          width: 300px;
        }
      }
    }
  }
  thead{
    tr{
      th{
        &:last-child{
          width: 300px;
        }
      }
    }
  }
}

.imageWrapper .images_outer .image_item{
  border: 1px solid rgb(223, 224, 225);
}

.product_image_column .form_row{
  margin-bottom: 0 !important;
}
.product_image_column .form_row .form_input .form_global{
  border: 0;
  border-top:1px solid rgb(223, 224, 225);
}

.ck-editor{
  width: 100% !important;
  .ck-content{
    min-height: 200px !important;
  }
}

.search_customer_outer{
  position: fixed;
  width: 50%;
  height: 200px;
  top: 50%;
  left: 50%;
  //background: red;
  transform: translate(-50%, -50%);
}

.combination_table{
  .cursor_pointer.selected{
    background: #e4e4e4;
  }
}

.text_left{
  text-align: left;
}

input[type="file"].form_global{
  padding: 6px 15px !important;
  cursor: pointer;
}

.blur_2px{
  -webkit-filter: blur(2px);
  filter: blur(2px);
  transition: all 0.2s ease;
}
.r_10{
  right: 10px;
}
.r_15{
  right: 15px;
}
.r_20{
  right: 20px;
}

.text_red{
  color: red;
}

.product_form_common_errors{
  position: absolute;
  margin-top: -10px;
}


.loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
  0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
  33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
  66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
  100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
}
#preloader_inner{
  height: auto;
}

::-ms-input-placeholder {
  color: rgb(121, 121, 121);
}

::placeholder {
  color: rgb(121, 121, 121);
}

.item_list_inner_wrap{
  .list_icon{
    .date{
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-transform: uppercase;
    }
  }
}

ul.left_nav_list > li .left_f_list .left_img{
  width: 15px;
}

.w_b_label{
  background: #000;
  color: #fff;
  border-radius: 4px;
  padding: 3px 5px;
}
.opacity_5{
  opacity: 0.5;
}
.opacity_1_important{
  opacity: 1 !important;
}

.ui-state-active, .ui-widget-content .ui-state-active{
  color: #FFF !important;
}

.dashboard_chart canvas{
  max-height: 450px;
}

.update_pass_outer{
  display: flex;
  justify-content: center;
  padding-top: 50px;
  .card{
    max-width: 700px;
  }
}

.pl_30{
  padding-left: 30px;
}
.d_block_important{
  display: block !important;
}
.text-capitalize{
  text-transform: capitalize;
}

.width_50_percent_imp{
  width: 50% !important;
}

.ck.ck-editor__main{
  .ck-content{
    overflow: auto;
    height: 350px;
    &::-webkit-scrollbar {
      width: 0px;
    }
    &::-webkit-scrollbar-track {
      background: var(--scrollbarBG);
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--thumbBG) ;
      border-radius: 0px;
      border: 0px solid var(--scrollbarBG);
    }
  }
}
.product_inventory_form{
  .form_input{
    .form_row {
      .form_input {
        width: 100%;
        padding-right: 5px;
      }
      &:last-child{
        .form_input {
          padding-right: 0px;
        }
      }
    }

  }
}

.product_submit_btn_row{
  top: -64px;
  position: absolute;
  right: 5px;
}

.dashboard_span_subheading{
  color: #818181;
  font-size: 12.6px;
  font-weight: 400;
}

.video_player, .youtube-embed   {
  width: 100%;
  height: 100%;
}

.youtube-embed iframe{
  width: 100%;
  height: 400px;
}

.hankook_tv_item{
  position: relative;
  .hankook_tv_video_actions{
    position: absolute;
    background: #e4e4e4;
    width: 100%;
    height: 30px;
    bottom: -4px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  &:hover{
    .hankook_tv_video_actions{
      opacity: 1;
      visibility: visible;
    }
  }
}

.tracing_div{
  top:0;
  right:0;
}

.react-datepicker-wrapper{
  width: 100%;
  .react-datepicker__input-container{
    input{
        width: 100%;
      display: block;
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      color: #000;
      position: relative;
      border: 1px solid rgb(222, 222, 221);
      height: 40px;
      padding: 5px 16px;
      background: transparent;
      cursor: text;
    }
  }
}

.report_table tbody tr:last-child {
  background: #000;
  color: #fff;
}