.m_0 {
    margin: 0!important;
}
// MARGIN TOP
.mt_0 {
    margin-top: 0!important;
}
.mt_5 {
    margin-top: 5px!important;
}
.mt_10 {
    margin-top: 10px!important;
}
.mt_15 {
    margin-top: 15px!important;
}
.mt_20 {
    margin-top: 20px!important;
}
.mt_30 {
    margin-top: 30px!important;
}
// MARGIN RIGHT
.mr_0 {
    margin-right: 0!important;
}
.mr_5 {
    margin-right: 5px!important;
}
.mr_10 {
    margin-right: 10px!important;
}
.mr_15 {
    margin-right: 15px!important;
}
.mr_20 {
    margin-right: 20px!important;
}
.mr_25 {
    margin-right: 25px!important;
}
// MARGIN BOTTOM
.mb_0 {
    margin-bottom: 0!important;
}
.mb_5 {
    margin-bottom: 5px!important;
}
.mb_10 {
    margin-bottom: 10px!important;
}
.mb_15 {
    margin-bottom: 15px!important;
}
.mb_20 {
    margin-bottom: 20px!important;
}
.mb_30 {
    margin-bottom: 30px!important;
}
// MARGIN LEFT
.ml_0 {
    margin-left: 0!important;
}
.ml_5 {
    margin-left: 5px!important;
}
.ml_10 {
    margin-left: 10px!important;
}
.ml_15 {
    margin-left: 15px!important;
}
.ml_20 {
    margin-left: 20px!important;
}
