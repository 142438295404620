@use "../base" as *;
@use "../mixin" as *;

.oder_filter_content {
    padding-top: 25px;
    .form_global {
        height: 42px;
    }
}

.order_filter_content {
    position: relative;
    .cart_item {
        position: absolute;
        right: 0;
        top: 10px;
        span {
            position: absolute;
            top: 56%;
            left: 50%;
            transform: translate(-50% , -50%);
            color: #fff;
            background: #000;
            border-radius: 50%;
            display: flex;
        }
    }
}

.order_cart {
    .order_cart_table_wrap {
        padding-top: 15px;
        .product_cart_info {
            display: flex;
            .product_img {}
            .product_text {
                padding-left: 10px;
                ul {
                    li {
                        margin-bottom: 3px;
                        span {
                            padding: 1px 3px;
                            border-radius: 2px;
                            min-width: 65px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
        
        .qty {
            display: flex;
            justify-content: center;
            text-align: center;
            label {
                flex: 1 0 100%;		
            }
            input {
                font-size: 1rem;
                text-align: center;
                border: 1px solid $border_color;
                width: 40px;
                background: transparent;
                color: $color_black;
                &:focus {
                    outline: 0;
                    box-shadow: none;
                    border: 1px solid $border_color;
                }
            }
            button {
                width: 35px;
                display: inline-flex;
                color: #fff;
                font-size: 1.5rem;
                background: #000;
                padding: 0;
                margin: 0;
                border-style: none;
                touch-action: manipulation;
                display: inline-block;
                border: none;
                cursor: pointer;
            }
            button.qtyminus {
                margin-right: 0.3rem;
            }
            button.qtyplus {
                margin-left: 0.3rem;
            }
        }
    }

    .order_cart_table {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;
        li {
            width: 100%;
            text-align: right;
            &:first-child {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
}


.order_summery_table {
    table {
        margin-bottom: 0;
        tr {
            td {
                padding: 8px 0px;
                border: none;
            }
        }
    }
}