@use "../base" as *;
@use "../mixin" as *;

.view_order_details {
    .img_wrap {
        display: flex;
        margin-left: -5px;
        margin-right: -5px;
        padding-top: 10px;
        .inner {
            width: 16.66667%;
            padding-left: 5px;
            padding-right: 5px;
            .inner_wrap {
                img {
                    width: 100%;
                }
                .text {
                    padding: 15px 0px;
                    h2 {
                        font-size: 15px;
                        margin-bottom: 0;
                        font-weight: 400;
                    }
                    p {
                        margin-bottom: 0;
                        color: #5F5F5F;
                        font-size: 14px;
                        padding: 3px 0px;
                    }
                    
                      .min-add-button {
                        padding: 0px 0px;
                        border: 1px solid $border_color;
                        margin-bottom: 15px;
                        .input-group {
                            display: flex;
                        }
                        button {
                            color: #000;
                            padding:5px 10px;
                            background: transparent;
                            border: none;
                            font-size: 10px;
                        }
                        
                        input {
                            height: 25px;
                            text-align: center;
                            font-weight: normal;
                            padding: 5px;
                            width: 100%;
                            border: none;
                            border-left: 1px solid $border_color;
                            border-right: 1px solid $border_color;
                          &:focus{
                            outline: 0;
                            box-shadow: none;
                          }
                        }
                       
                      }
                }
                
            }
        }
    }
}

.checkout_summery {
    table {
        border-top: none;
        border-bottom: 1px solid rgb(222, 222, 221);
        tr {
            &:last-child {
                td {
                    padding-bottom: 20px;
                }
            }
            td {
                padding: 8px 0px;
                border: none;
            }
        }
        &.total_table {
            border-bottom: none;
            border-top: 1px solid rgb(222, 222, 221);
            tr {
                &:first-child {
                    td {
                        border-bottom: 1px solid rgb(222, 222, 221);
                        padding: 15px 0px;
                    }
                }
                &:last-child {
                    td {
                        padding: 15px 0px 0px;
                    }
                }
            }
        }
    }
}