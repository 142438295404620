
@use "../base" as *;
@use "../mixin/" as *;

.dropzone {
    min-height: 150px;
    border: 2px solid $border_color;
    // background: $dropdown_bg;
    padding: 20px 20px;
    position: relative;
    .dz-preview {
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin: 10px;
        min-height: 100px;
    }
    .dz-preview.dz-image-preview {
        background: $main_bg;
    }
    .dz-message {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        font-size: 20px;
    }
    .dz-preview .dz-image img {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .dz-preview.dz-file-preview .dz-image {
        border-radius: 3px;
        background: $main_bg;
    }
    .dz-preview .dz-image {
        border-radius: 3px;
    }
    .dz-preview .dz-success-mark svg, .dz-preview .dz-error-mark svg {
        display: block;
        width: 30px;
        height: 30px;
    }
    .dz-preview .dz-success-mark, .dz-preview .dz-error-mark {
        margin-left: -15px;
        margin-top: -15px;
    }
    .dz-preview .dz-remove {
        font-size: 14px;
        text-align: center;
        display: block;
        cursor: pointer;
        border: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        cursor: pointer;
        background-color: $color_danger;
        border: 1px solid transparent;
        position: relative;
        outline: 0;
        box-shadow: none;
        border-radius: 2px;
        text-transform: capitalize;
        white-space: nowrap;
        padding: 2px 6px;
        color: #fff;
        left: 50%;
        transform: translateX(-50%);
        z-index: 20;
        transition: all .3s ease-in-out;
        &:hover {
            transition: all .3s ease-in-out;
            box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.1), 0 4px 23px 0 rgba(0, 0, 0, 0.1), 0 8px 10px -5px rgba(0, 0, 0, 0.1);
        }
        &:focus {
            outline: 0;
            box-shadow: none;
            box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.2);
        }
    }
}