@use "../base" as *;
.card {
    // border-radius: 3px;
    margin-bottom: 15px;
    border: 1px solid $border_color_type2;
    .card_heading {
        padding: 10px 15px;
        font-size: 16px;
        line-height: 16px;
    }
    .card_body {
        padding: 20px 15px;
    }
}