/*-------------------------
    FONT SIZE
-------------------------*/
.font_18p {
    font-size: 18px!important;
}
.font_16p {
    font-size: 16px!important;
}
.font_15p {
    font-size: 15px!important;
}
.font_14p {
    font-size: 14px!important;
}

.font_12p {
    font-size: 12px!important;
}