@use "../base" as *;
/* Outer */
.modal {
    position: fixed;
    left: 0;
    top: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2000;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    backface-visibility: hidden;
    .modal_overlay {
        width:100%;
        height:100%;
        position:fixed;
        top:0px;
        left:0px;
        background: rgba(0,0,0,.5);
    }
    &.open_modal {
        display: flex;
        opacity: 1;
        visibility: visible;
        transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
        .modal_wrap {
            transform: scale(1); 
            transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
        }
    }
    .modal_wrap {
        position: relative;
        z-index: 200;
        max-width: 90%;
        max-height: 90%;
        border-radius: 0;
        background: $nav_bg;
        -webkit-backface-visibility: hidden;
        -webkit-overflow-scrolling: touch;
        -webkit-font-smoothing: subpixel-antialiased; 
        transform: scale(.98);
        transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
        border-radius: 5px;
        overflow: hidden;
        > .close_icon {
            top: 12px;
            right: 10px;
            transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
        }
        .modal_header {
            padding: 10px;
            border-bottom: 1px solid $border_color_type2;
            position: relative;
            background: $nav_bg;
            min-height: 38px;
            h3 {
                font-size: 18px;
                font-weight: 500;
            }
            .close_icon {
                top: 12px;
                right: 10px;
            }
        }
        .modal_inner {
            max-width: 100%;
            max-height: calc(100% - 37px);
            overflow: auto;
            .modal_content {
                background: $nav_bg;
                padding: 10px;
            }
        }  
    }
}
html {
    --scrollbarBG: $main_bg;
    --thumbBG: rgb(223, 224, 225);
}
.modal_inner::-webkit-scrollbar {
    width: 4px;
}
.modal_inner {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.modal_inner::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
}
.modal_inner::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 2px;
    border: 3px solid var(--scrollbarBG);
}

body.model_open {
    overflow: hidden;
}

.modal_1720p {
    width: 1720px;
}
.modal_1700p {
    width: 1700px;
}
.modal_1600p {
    width: 1600px;
}
.modal_1500p {
    width: 1500px;
}
.modal_1400p {
    width: 1400px;
}
.modal_1300p {
    width: 1300px;
}
.modal_1200p {
    width: 1200px;
}
.modal_1080p {
    width: 1080px;
}
.modal_1060p {
    width: 1060px;
}
.modal_950p {
    width: 950px;
}
.modal_850p {
    width: 850px;
}
.modal_700p {
    width: 700px;
}
.modal_600p {
    width: 600px;
}
.modal_585p {
    width: 585px;
}
.modal_500p {
    width: 500px;
}
.modal_470p {
    width: 470px;
}
.modal_380p {
    width: 380px;
}
.modal_250p {
    width: 250px;
}
.modal_200p {
    width: 200px;
}
.modal_150p {
    width: 150px;
}
.modal_100p {
    width: 100px;
}

