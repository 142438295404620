.p_0 {
    padding: 0!important;
}
.p_5 {
    padding: 5px!important;
}
.p_10 {
    padding: 10px!important;
}
.p_15 {
    padding: 15px!important;
}
.p_20 {
    padding: 20px!important;
}
// MARGIN TOP
.pt_0 {
    padding-top: 0!important;
}
.pt_2 {
    padding-top: 2px!important;
}
.pt_5 {
    padding-top: 5px!important;
}
.pt_10 {
    padding-top: 10px!important;
}
.pt_15 {
    padding-top: 15px!important;
}
.pt_20 {
    padding-top: 20px!important;
}
.pt_30 {
    padding-top: 30px!important;
}
// padding RIGHT
.pr_0 {
    padding-right: 0!important;
}
.pr_5 {
    padding-right: 5px!important;
}
.pr_7_half {
    padding-right: 7.5px!important;
}
.pr_10 {
    padding-right: 10px!important;
}
.pr_15 {
    padding-right: 15px!important;
}
.pr_20 {
    padding-right: 20px!important;
}
.pr_30 {
    padding-right: 30px!important;
}
// padding BOTTOM
.pb_0 {
    padding-bottom: 0!important;
}
.pb_5 {
    padding-bottom: 5px!important;
}
.pb_10 {
    padding-bottom: 10px!important;
}
.pb_15 {
    padding-bottom: 15px!important;
}
.pb_20 {
    padding-bottom: 20px!important;
}
.pb_30 {
    padding-bottom: 30px!important;
}
// padding LEFT
.pl_0 {
    padding-left: 0!important;
}
.pl_5 {
    padding-left: 5px!important;
}
.pl_7_half {
    padding-left: 7.5px!important;
}
.pl_10 {
    padding-left: 10px!important;
}
.pl_15 {
    padding-left: 15px!important;
}
.pl_20 {
    padding-left: 20px!important;
}
.pl_30 {
    padding-left: 30px!important;
}

