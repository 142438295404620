@use "../mixin" as *;
@use "../base" as *;
.common_title {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 28px;
    // letter-spacing: 1px;
    font-weight: 600;
    position: relative;
    // &:after {
    //     position: absolute;
    //     content: "";
    //     bottom: -7px;
    //     left: 0;
    //     width: 40px;
    //     height: 2px;
    //     background: $color_black;
    // }
}

.common_sub_title {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 28px;
    // letter-spacing: 1px;
    font-weight: 600;
    position: relative;
}