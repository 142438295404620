
@use "../base" as *;
.tabs{
    border-bottom: 1px solid $border_color_type2;
    ul {
        display: flex;   
        li {
            padding: 8px 0px;
            margin-right: 50px;
            // background: $dropdown_bg;
            border-style: solid;
            border-bottom-color: transparent;
            border-right-color: $border_color;
            border-width: 0px 0px 2px 0px;
            color: #979797;
            font-size: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: pointer;
            white-space: nowrap;
            transition: all .3s;
            i {
                margin-right: 5px;
            }
            &.active {
                position: relative;
                color: $color_black;
                border-color: $border_color;
                border-bottom-color: $color_black;
                transition: all .3s;
            }
            // &:hover {
            //     position: relative;
            //     color: $color_black;
            //     border-top-color: $hover_color3;
            //     border-color: $border_color;
            //     transition: all .3s;
            // }
            &:last-child {
                border-width: 0px 0px 2px 0px;
            }
        }
    }
}

.tab_content_wrapper {
    border-style: solid;
    border-color: $border_color;
    border-width: 0px 1px 1px 1px;
}
.tabs ul.tab_two li{
    width: 50%;
}
.tabs ul.tab_three li{
    width: 50%;
}

.tabs ul.tab_four li{
    width: 25%;
}
.tabs ul.tab_six li{
    width: 16.66%;
}
.tab_three a ,
.tabs ul.tab_three li{
    width: 33.33%;
}
.tab_content_wrapper {
    width: 100%;
    // border: 1px solid $border_color;
    padding: 15px;
}

.tab_content {
    display: none;
}
.tab_content.show {
    display: block;
}
.show .fadein {
    animation: fadein 0.7s ease;
}
@keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

