@use "../mixin" as *;
@use "../base" as *;
// .pagination_wrap {
//     display: flex;
// }
// .pagination {
//     padding: 5px;
// }
// .pagination li {
//     float: left;
//     margin-right: 5px;
//     list-style: none;
// }
// .pagination a,
// .pagination span {
//     display: block;
//     padding: 4px 11px;
//     background: #fff;
//     color: $color_main;
//     text-decoration: none;
//     font-size: 12px;
//     font-family: Verdana;
//     line-height: 25px;
//     height: 32px;
// }
// .pagination .current {
//     background-color: #fff ;
//     color: $color_main;
//     opacity: .6;
// }
// .pagination .dots {
//     padding: 0px;
//     background-color: transparent;
// }
// .pagination .prev,
// .pagination .next {
//     height: 32px;
//     text-align: center;
//         padding: 4px 11px;
// }
// .pagination .disabled {
//     color: #bbb;
// }



.retail_pagination  {
    padding: 7px 0px;
    @include d_flex_btwn;
    p {
        margin-bottom: 0;
        font-size: 16px;
        color: #818181;
    }
    ul {
        @include d_flex_center;
        margin-left: -0.25rem;
        margin-right: -0.25rem;
        li {
            @include d_flex_center;
            padding-left: 0.25rem;
            padding-right: 0.25rem;
            &.active {
                span {
                    // background: $themeColor;
                    // border-color: $themeColor;
                    transition: all .2s;
                    // color: #fff;
                    cursor: auto;
                }
            }
             a {
                @include d_flex_center;
                height: 1.8rem;
                width: 1.8rem;
                display: flex;
                align-items: center;
                justify-content: center;
                // border-radius: 9999px;
                border-width: 1px;
                border-style: solid;
                border-color: $border_color_type2;
                font-size: 14px;
                font-weight: 400;
                transition: all .2s;
                svg {
                    width: 1.25rem;
                    height: 1.25rem;
                }
                &:hover {
                    // background: $themeColor;
                    // border-color: $themeColor;
                    // color: #fff;
                    transition: all .2s;
                }
             }
             span {
                @include d_flex_center;
                height: 1.8rem;
                width: 1.8rem;
                display: flex;
                align-items: center;
                justify-content: center;
                // border-radius: 9999px;
                border-width: 1px;
                border-style: solid;
                border-color: $border_color_type2;
                font-size: 14px;
                font-weight: 400;
                transition: all .2s;
                &:hover {
                    // background: $themeColor;
                    // border-color: $themeColor;
                    // color: #fff;
                    transition: all .2s;
                }
                svg {
                    width: 1.25rem;
                    height: 1.25rem;
                }
             }
        }
    }
}