
@use "../mixin" as *;
@use "../base" as *;
.color_primary {
    color: $color_primary!important;
}
.color_info {
    color: $color_info!important;
}
.color_success {
    color: $color_success!important;
}
.color_warning {
    color: $color_warning!important;
}
.color_danger {
    color: $color_danger!important;
}