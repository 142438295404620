
@use "../base" as *;
.dropdown {
    position: relative;
    > button {
        box-shadow: none;
        border: none;
        background: transparent;
        padding: 6px 0px;
        cursor: pointer;
        > i {
            margin-right: 5px;
        }
    }
}
.dropdown_menu {
    display: none;
    position: absolute;
    right: 0;
    z-index: 1000;
    top: 100%;
    min-width: 175px;
    // color: #212529;
    text-align: left;
    list-style: none;
    // background-color: $dropdown_bg;
    background: #fff;
    background-clip: padding-box;
    box-shadow: 0px 0px 6px 1px rgba(39, 55, 76, 0.2);
    padding: 10px 0px;
    ul{
        flex-direction: column;
        li {
            a {
                // display: block;
                // width: 100%;
                // padding: 0.25rem 1.5rem;
                // clear: both;
                // font-weight: 400;
                // text-align: inherit;
                // white-space: nowrap;
                // background-color: transparent;
                // border: 0;
                color: $color_black;
                padding: 7px 15px;
                display: block;
                font-size: 14px;
                // border-bottom: 1px solid $border_color;
                i {
                    margin-right: 5px;
                }
                // &:hover {
                //     background: $hover_color2;
                // }
            }
            &:last-child {
                a {
                    border-bottom: 1px solid transparent; 
                }
            }
        }
    }
}
.dropdown_menu.left {
    right: auto;
    left: 0;
}

// .dropdown_global {
//         position: relative;
//         > button {
//             box-shadow: none;
//             border: none;
//             background: transparent;
//             padding: 10px 0px;
//             cursor: pointer;
//             display: flex;
//             > i {
//                 margin-right: 0px;
//                 margin-left: 10px;
//                 font-size: 15px;
//             }
//     }
//     .dropdown_menu {
//         ul {
//             li {
//                 transition: all .2s;
//                 padding: 6px 8px;
//                 border-bottom: 1px solid $border_color;
//                 a {
//                     padding: 0;
//                 }
//                 &:hover {
//                     transition: all .2s;
//                     background: #f0f1f7;
//                 }
//             }
//         }
//     }
// }