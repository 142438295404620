// /*preloader*/
// .stylepick_loading{
//     position: fixed;
//     top: 0;
//     left: 0;
//     background: #fff;
//     width: 100%;
//     height: 100%;
//     z-index: 9999;
// }

// .loader {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50% , -50%);
// }

// .line {
//   animation: expand 1s ease-in-out infinite;
//   border-radius: 10px;
//   display: inline-block;
//   transform-origin: center center;
//   margin: 0 3px;
//   width: 1px;
//   height: 25px;
// }

// .line:nth-child(1) {
//   background: #27ae60;
// }

// .line:nth-child(2) {
//   animation-delay: 180ms;
//   background: #f1c40f;
// }

// .line:nth-child(3) {
//   animation-delay: 360ms;
//   background: #e67e22;
// }

// .line:nth-child(4) {
//   animation-delay: 540ms;
//   background: #2980b9;
// }

// @keyframes expand {
//   0% {
//     transform: scale(1);
//   }
//   25% {
//     transform: scale(2);
//   }
// }

// @keyframes move {
//   0%   {left: 0;}
//   100% {left: 3.5em;}
// }

// .is_loading_button {
//   overflow: hidden;
//   position: relative;
//   &:before, &:after {
//       font-weight: bold;
//       font-size: 1.5em;
//       letter-spacing: 0.05em;
//       display: block;
//       position: absolute;
//       top: 0;
//       right: 0;
//       bottom: 0;
//       left: 0;
//   }

//   // ellipsis
//   &:before {
//       content: "...";
//       background: inherit;
//       font-weight: bold;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       top: -6px;
//   }
//   // animation
//   &:after {
//       content: "";
//       background: inherit;
//       border-radius: 100%;
//       opacity: .75;
//       width: 2em;
//       margin: auto;
//       left: 0;
//       animation: move 1s infinite;
//   }
// }

// .is_loading_div {
//     overflow: hidden;
//     position: relative;
//     background: #fff;
//     &:before, &:after {
//         font-weight: bold;
//         font-size: 3.5em;
//         letter-spacing: 0.05em;
//         display: block;
//         position: absolute;
//         top: 0;
//         right: 0;
//         bottom: 0;
//         left: 0;
//     }

//     // ellipsis
//     &:before {
//         content: "...";
//         background: inherit;
//         font-weight: bold;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         top: -6px;
//         z-index: 2000;
//     }
//     // animation
//     &:after {
//         content: "";
//         background: inherit;
//         border-radius: 0;
//         opacity: .75;
//         width: 2em;
//         margin: auto;
//         left: 0;
//         animation: move 1s infinite;
//         z-index: 3000;
//     }
// }



/*css with 2 animation ---> shimmer, shadow   */
.preloader{
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background: #f7f7f7;
    z-index: 2000;
}
.box_section{
    width: 120px;
    height: 120px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
    background: white;
    border-radius: 20px;
    animation:1s ease-in-out alternate infinite shadows;
}
.site_logo{
    width:100px;
}
.img_filter{
    width: 120px;
    height: 120px;
    opacity:0.45 ;
    position: absolute;
    -webkit-animation-duration: 0.9s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    background: #f6f7f9;
    background-image: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 40%, #f6f7f9 100%);
    background-repeat: no-repeat;
}
@keyframes placeHolderShimmer{

  0% {
      background-position: -468px 0;
  }
  100% {
      background-position: 468px 0;
  }
  }
@keyframes shadows{
  0%{
      box-shadow:none
  }
  100%{
      box-shadow: 9px 16px 8px rgb(227, 227, 227);
  }
}


.loading_overlay {
    position: relative;
    &:after {
        margin-top: -15px;
        width: 30px;
        height: 30px;
        border: 3px solid rgba(92,91,98,.6);
        border-top: 2px solid #a9acb1;
        border-radius: 50%;
        animation: rotate 1.5s linear infinite;
        content: "";
        z-index: 4;
        top: 50%;
        left: 50%;
        transform: translate(-50% , -50%);
        position: absolute;
    }
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        background: rgba(12,12,12,.8);
        content: "";
        z-index: 3;
    }
}

@keyframes rotate {
  0% {transform: rotate(0);}
  100% {transform: rotate(360deg);}
}