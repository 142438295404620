
@use "../base/variable" as *;
/*----------------------------
    COMMON  COLOR
----------------------------*/
.main_bg {
    background: $main_bg!important;
}
.color_grey_bg_type_2 {
    background: #F6F6F6!important;
}
// .white_bg {
//     background: $color_white!important;
// }
// .black_bg {
//     background: $color_black!important;
// }
// .info_bg  {
//     background: $color_info!important;
// }
// .warning_bg {
//     background: $color_warning!important;
// }
// .danger_bg {
//     background: $color_danger!important;
// }
// .secondary_bg {
//     background: $color_secondary!important;
// }
// .transparent_bg {
//     background: transparent!important;
// }
// .color_white {
//     color: $color_white!important;
// }
.color_primary {
    color: $color_primary!important;
}
.color_black {
    color: $color_black!important;
}
.color_info {
    color: $color_info!important;
}
.color_success {
    color: $color_success!important;
}
.color_success_type_2 {
    color: $color_success_type2!important;
}
.color_warning {
    color: $color_warning!important;
}
.color_danger {
    color: $color_danger!important;
}
.color_danger_type_2 {
    color: $color_danger_type_2!important;
}
.color_grey {
    color: #6B7280;
}
.color_grey_type_2 {
    color: #F6F6F6;
}
