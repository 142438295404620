.width_full {
    width: 100%!important;
}
.width_auto {
    width: auto!important;
}
.width_15p {
    width: 15px!important;
}
.width_50p {
    width: 50px!important;
}
.width_60p {
    width: 60px!important;
}
.width_80p {
    width: 80px!important;
}
.width_85p {
    width: 85px!important;
}
.width_100p {
    width: 100px!important;
}
.width_120p {
    width: 120px!important;
}
.width_140p {
    width: 140px!important;
}
.width_150p {
    width: 150px!important;
}
.width_200p {
    width: 200px!important;
}
.width_260p {
    width: 260px!important;
}
.width_500p {
    width: 500px!important;
}
/*----------------------------
    MIN WIDTH
----------------------------*/
.min_width_100p {
    min-width: 100px!important;
}
.min_width_150p {
    min-width: 150px!important;
}
.min_width_175p {
    min-width: 175px!important;
}

.width_80_percent {
    width: 80%!important;
}