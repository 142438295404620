@use "base" as *;
@use "mixin" as *;

// @use 'base';
// @use 'mixin';
@use 'abstracts';
@use 'components';
@use 'layout';
@use 'partials';
@use 'pages';
@use 'utilities';

/*******************************
   MAIN PAGE
********************************/
#content_wrap {
    padding-left: 0px;
    // padding-top: 125px;
}

.inline_card {
    padding : 10px;
    // padding-left: 55px;
    position: relative;
    border: 1px solid $border_color;
    background: $nav_bg;
    margin-bottom: 10px;
    border-radius: 3px;
    overflow: hidden;
    span {
        position: absolute;
        top: 10px;
        right: 10px;
        // background: $dropdown_bg;
        // height: 100%;
        i {
            // position: absolute;
            // top: 50%;
            // left: 10px;
            // transform: translateY(-50%);
            font-size: 25px;
            display: inline-flex;
        }
    }
    p {
        font-weight: 500;
        
        font-size: 14px;
    }
    h2 {
        margin-bottom: 10px;
        font-size: 25px;
        margin-bottom: 15px;
        font-weight: 400;
    }
    svg {
        height: 100px;
        width: 100%;
        g {
            width: 100%;
        }
        .ct-area {
            fill: #112547;
        }
        .ct-line {
            fill: #112547;
        }
    }
}

/*----------------------------------
    PAYMENT COMMON INFORMATION
----------------------------------*/
.payment_common_info {
    .card_body {
        h2 {
            @include d_flex_btwn;
            color: #fff;
            padding-bottom: 15px;
            font-size: 18px;
            span {
                text-decoration: underline;
                text-transform: uppercase;
                font-weight: 500;
            }
            button {
                padding: 2px 8px;
                font-size: 12px;
            }
        }
        ul {
            li {
                margin: 6px 0px;
                font-size: 15px;
                color: #fff;
            }
        }
    }
}

/*---------------------------
    PRICING TABLE
----------------------------*/
.pricing_table {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 20px 0px;
    margin-left: -12px;
    margin-right: -12px;
    .inner {
        width: 20%;
        padding-left: 12px;
        padding-right: 12px;
        margin: 10px 0px;
        .inner_wrap {
            box-shadow: 2px 2px 6px 0px rgba(50, 50, 50, .32);
            padding: 40px 25px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            border: 2px solid #868D9B;
            height: 100%;
            &.color_type_2 {
                border: 2px solid #8850FB;
                .sticky_badge {
                    background: #8850FB;
                }
                .pricing_text {
                    cursor: pointer;
                    border-radius: 40px;
                    padding: 1px 15px;
                    display: inline-block;
                    width: 100%;
                    margin: 11px 0;
                    outline: 0;
                    box-shadow: none;
                    border: none;
                    position: relative;
                    border: 2px solid #fff;
                    transition: all .3s;
                    &:hover {
                        background: #8850FB;
                        border: 2px solid #8850FB;
                        color: #fff;
                    }
                    &.active {
                        background: #8850FB;
                        border: 2px solid #8850FB;
                        color: #fff;
                        &:hover {
                            color: #fff;
                        }
                    }
                }
            }
            .sticky_badge {
                position: absolute;
                top: 0px;
                left: 0px;
                font-size: 15px;
                padding: 8px;
                background: $color_success_hover;
                color: #fff;
                font-weight: 600;
            }
            h2 {
                font-size: 32px;
                font-weight: 500;
                margin-bottom: 5px;
                padding-top: 20px;
                color: #fff;
            }
            h3 {
                font-size: 19px;
                font-weight: 400;
                margin-bottom: 25px;
                color: #fff;
            }
            p {
                margin-bottom: 5px;
                color: #fff;
                text-align: center;
                &.opacity_none {
                    opacity: 0;
                    margin-bottom: 0;
                }
            }
            .pricing_text {
                cursor: pointer;
                border-radius: 40px;
                padding: 1px 15px;
                display: inline-block;
                width: 100%;
                margin: 11px 0;
                outline: 0;
                box-shadow: none;
                position: relative;
                border: 2px solid #fff;
                transition: all .3s;
                &:hover {
                    background: #fff;
                    border: 2px solid #fff;
                    color: #000;
                }
                &:focus {
                    outline: 0;
                    box-shadow: none;
                    border: 2px solid #fff;
                }
                .price_old {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 10px;
                    font-size: 18px;
                    text-decoration-line: line-through;

                }
                .current_price {
                    
                    .currency {
                        font-size: 20px;
                        font-weight: 600;
                    }
                    .big_number {
                        font-size: 30px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

.payment_method {
    > ul {
        display: flex;
        margin-left: -5px;
        margin-right: -5px;
        > li {
            width: 33.3333%;
            padding-left: 5px;
            padding-right: 5px;
            > .inner_wrap {
                background: #535B6E;
                border: 2px solid #535B6E;
                
                img {
                    max-width: 100%;
                }
            }
            &.active {
                .inner_wrap {
                    border: 2px solid #FF9D00;
                }
            }
        }
    }
    .payment_method_details {
        display: flex;
        flex-direction: column;
        padding: 20px 0px;
        .inner_wrap {
            .payment_common_info {
                .card_body {
                    h2 {
                        @include d_flex_btwn;
                        color: #fff;
                        padding-bottom: 15px;
                        font-size: 16px;
                        span {
                            text-decoration: underline;
                            text-transform: uppercase;
                            font-weight: 500;
                        }
                        button {
                            padding: 2px 8px;
                            font-size: 12px;
                        }
                    }
                    ul {
                        li {
                            margin: 6px 0px;
                            font-size: 12px;
                            color: #fff;
                        }
                    }
                }
                .p_d_wrap {
                    display: flex;
                    align-items: center;
                    .left {
                        padding-right: 10px;
                        img {
                            width: 80px;
                            border-radius: 5px;
                        }
                    }
                }
            }
            &.active {
                .payment_common_info {
                    border: 1px solid #00A621;
                }
            }
        }
    }
}

.total_calc_table {
    .title_wrap {
        border-bottom: 1px solid #fff;
        padding: 5px 0px;
        margin-bottom: 10px;
        .title {
            font-size: 18px;
            text-transform: capitalize;
            color: #009844;
        }
    }
    table {
        tr {
            td {
                padding: 9px 4px;
                vertical-align: middle;
                color: #fff;
                font-size: 16px;
                &:last-child {
                    font-size: 18px;
                    font-weight: 500;
                    text-align: right;
                }
            }
        }
    }
    .checkout_btn {
        @include d_flex_end;
        button {
            width: 100%;
            margin-bottom: 10px;
            height: 50px;
            font-size: 18px;
            font-weight: 600;
            padding: 8px 20px 4px;
            background: #009844;
            color: #fff;
            border: none;
            max-width: 200px;
        }
    }
    table.total tr td:last-child {
        font-size: 28px;
        font-weight: 500;
        text-align: right;
    }
}


/*---------------------------
    TRANSACTION PAGE
----------------------------*/


.transaction_page_table {
    tr {
        td {
            padding: 10px 0px;
            border: none;
            &:first-child {
                font-weight: 500;
            }
        }
    }
}


