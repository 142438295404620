.height_full {
    height: 100%!important;
}
.height_auto {
    height: auto!important;
}
.height_15p {
    height: 15px!important;
}
.height_25p {
    height: 25px!important;
}
.height_30p {
    height: 30px!important;
}