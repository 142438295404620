
.chat_box_wrap {
    background: #f1f5f9!important
}

.chat_box_wrap .chat_box {
    display: flex;
    flex-wrap: wrap;
    height: 700px
}

.chat_box_wrap .chat_box .chat_box_left {
    width: 20%;
    padding-right: 15px;
    height: 700px;
    overflow: hidden;
    position: relative
}

.chat_box_wrap .chat_box .chat_box_left .search_box_wrap {
    padding: 0 0 15px
}

.chat_box_wrap .chat_box .chat_box_left .search_box_wrap .search_box {
    position: relative
}

.chat_box_wrap .chat_box .chat_box_left .search_box_wrap .search_box input {
    height: 46px;
    padding-left: 15px
}

.chat_box_wrap .chat_box .chat_box_left .search_box_wrap .search_box button {
    position: absolute;
    top: 0;
    right: 0
}

.chat_box_wrap .chat_box .chat_box_left .user_list ul {
    overflow: auto;
    height: calc(100% - 61px);
    width: calc(100% - 15px);
    position: absolute;
    -ms-overflow-style: none;
    scrollbar-width: none
}

.chat_box_wrap .chat_box .chat_box_left .user_list ul::-webkit-scrollbar {
    display: none
}

.chat_box_wrap .chat_box .chat_box_left .user_list ul li .user_list_inner {
    display: flex;
    position: relative;
    padding: 20px;
    padding-left: 10px;
    margin-bottom: 10px;
    background: #fff;
    border-radius: 5px
}

.chat_box_wrap .chat_box .chat_box_left .user_list ul li .user_list_inner .unseen_notification {
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 20px;
    background: #312e81;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px
}

.chat_box_wrap .chat_box .chat_box_left .user_list ul li .user_list_inner .img_wrap .img {
    overflow: hidden;
    border-radius: 50%;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    top: 4px;
    position: relative
}

.chat_box_wrap .chat_box .chat_box_left .user_list ul li .user_list_inner .img_wrap .img img {
    overflow: hidden;
    width: 30px;
    height: 30px
}

.chat_box_wrap .chat_box .chat_box_left .user_list ul li .user_list_inner .text h3 {
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 5px
}

.chat_box_wrap .chat_box .chat_box_left .user_list ul li .user_list_inner .text .chat_text p {
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
    display: -webkit-inline-box
}

.chat_box_wrap .chat_box .chat_box_left .user_list ul li .user_list_inner .text .time {
    position: absolute;
    right: 15px;
    top: 23px;
    font-size: .75rem;
    color: #94a3b8
}

.chat_box_wrap .chat_box .chat_box_right {
    width: 80%;
    padding-left: 15px;
    background: #fff;
    height: 700px;
    position: relative
}

.chat_box_wrap .chat_box .chat_box_right .user_list_inner {
    display: flex;
    position: relative;
    padding: 13px;
    padding-left: 0;
    margin-bottom: 10px;
    background: #fff;
    border-radius: 5px
}

.chat_box_wrap .chat_box .chat_box_right .user_list_inner .unseen_notification {
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 20px;
    background: #312e81;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px
}

.chat_box_wrap .chat_box .chat_box_right .user_list_inner .img_wrap .img {
    overflow: hidden;
    border-radius: 50%;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    position: relative
}

.chat_box_wrap .chat_box .chat_box_right .user_list_inner .img_wrap .img img {
    overflow: hidden;
    width: 40px;
    height: 40px
}

.chat_box_wrap .chat_box .chat_box_right .user_list_inner .text h3 {
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 5px
}

.chat_box_wrap .chat_box .chat_box_right .user_list_inner .text .chat_text p {
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
    display: -webkit-inline-box
}

.chat_box_wrap .chat_box .chat_box_right .user_list_inner .text .time {
    position: absolute;
    right: 15px;
    top: 23px;
    font-size: .75rem;
    color: #94a3b8
}

.chat_box_wrap .chat_box .chat_box_right .left-nav-bottom-wrap {
    padding: 0 0 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-top: 1px solid #d6dee2;
    font-size: 15px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 70px)
}

.chat_box_wrap .chat_box .chat_box_right .left-nav-bottom-wrap .right_chat_box {
    width: 100%;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none
}

.chat_box_wrap .chat_box .chat_box_right .left-nav-bottom-wrap .right_chat_box::-webkit-scrollbar {
    display: none
}

.chat_box_wrap .chat_box .chat_box_right .left-nav-bottom-wrap .right_chat_box .chat_box_inner ul {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column
}

.chat_box_wrap .chat_box .chat_box_right .left-nav-bottom-wrap .right_chat_box .chat_box_inner ul li {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 5px
}

.chat_box_wrap .chat_box .chat_box_right .left-nav-bottom-wrap .right_chat_box .chat_box_inner ul li.left {
    justify-content: flex-start
}

.chat_box_wrap .chat_box .chat_box_right .left-nav-bottom-wrap .right_chat_box .chat_box_inner ul li.left .chat_box_inline {
    padding-left: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start
}

.chat_box_wrap .chat_box .chat_box_right .left-nav-bottom-wrap .right_chat_box .chat_box_inner ul li.left .chat_box_inline .main_chat_text {
    background: #f1f5f9;
    order: 2;
    color: #1e293b
}

.chat_box_wrap .chat_box .chat_box_right .left-nav-bottom-wrap .right_chat_box .chat_box_inner ul li.left .chat_box_inline .right_arrow {
    order: 1;
    margin-right: 10px
}

.chat_box_wrap .chat_box .chat_box_right .left-nav-bottom-wrap .right_chat_box .chat_box_inner ul li .chat_box_inline {
    padding-left: 15px;
    width: 100%;
    position: relative;
    padding-right: 5px;
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end
}

.chat_box_wrap .chat_box .chat_box_right .left-nav-bottom-wrap .right_chat_box .chat_box_inner ul li .chat_box_inline .conversation_name {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column
}

.chat_box_wrap .chat_box .chat_box_right .left-nav-bottom-wrap .right_chat_box .chat_box_inner ul li .chat_box_inline .conversation_name p {
    margin-bottom: 5px
}

.chat_box_wrap .chat_box .chat_box_right .left-nav-bottom-wrap .right_chat_box .chat_box_inner ul li .chat_box_inline .conversation_name a {
    background-color: #6f7782;
    color: #fff;
    padding: 2px 11px;
    border-radius: 15px;
    font-size: 12px
}

.chat_box_wrap .chat_box .chat_box_right .left-nav-bottom-wrap .right_chat_box .chat_box_inner ul li .chat_box_inline .right_arrow {
    order: 2
}

.chat_box_wrap .chat_box .chat_box_right .left-nav-bottom-wrap .right_chat_box .chat_box_inner ul li .chat_box_inline .right_arrow .img {
    overflow: hidden;
    border-radius: 50%;
    margin-left: 10px;
    width: 40px;
    height: 40px;
    position: relative
}

.chat_box_wrap .chat_box .chat_box_right .left-nav-bottom-wrap .right_chat_box .chat_box_inner ul li .chat_box_inline .right_arrow .img img {
    overflow: hidden;
    width: 40px;
    height: 40px
}

.chat_box_wrap .chat_box .chat_box_right .left-nav-bottom-wrap .right_chat_box .chat_box_inner ul li .chat_box_inline .main_chat_text {
    background-color: #312e81;
    padding: 9px 15px;
    border-radius: 5px;
    word-wrap: break-word;
    color: #fff;
    font-size: 14px;
    line-height: 20px
}

.chat_box_wrap .chat_box .chat_box_right .left-nav-bottom-wrap .right_chat_box .chat_box_inner ul li .chat_box_inline .main_chat_text .time {
    opacity: .8;
    font-size: 12px;
    padding-top: 2px
}

.chat_box_wrap .chat_box .chat_box_right .left-nav-bottom-wrap .chat_input_box {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid rgba(226,232,240,.6)
}

.chat_box_wrap .chat_box .chat_box_right .left-nav-bottom-wrap .chat_input_box input {
    width: 100%;
    border: none;
    height: 48px;
    padding: 2px 50px 2px 20px
}

.chat_box_wrap .chat_box .chat_box_right .left-nav-bottom-wrap .chat_input_box input:focus {
    outline: 0;
    box-shadow: none
}

.chat_box_wrap .chat_box .chat_box_right .left-nav-bottom-wrap .chat_input_box button {
    position: absolute;
    right: 10px;
    top: 12px;
    border: none;
    background: #fff
}

.chat_box_wrap .chat_box .chat_box_right .left-nav-bottom-wrap .chat_input_box button:focus {
    outline: 0;
    box-shadow: none
}

.attatch_file_btn {
    background: transparent;
    padding: 0;
    border-radius: 8px;
    font-size: 22px;
    position: absolute;
    left: 10px;
    top: 10px;
    cursor: pointer;
}

.chat_box_wrap .chat_box .chat_box_right .left-nav-bottom-wrap .chat_input_box input {
    padding-left: 45px;
    padding-right: 85px;
}

.emoji_button {
    position: absolute;
    right: 55px;
    top: 12px;
    border: none;
    background: #fff;
    cursor: pointer;
}

.emoji_button span{
    font-size: 25px;
    display: block;
    color: #b1c6d0;
}




// .chat_box_wrap {
//     background: rgb(241 245 249)!important;
//     .chat_box {
//         display: flex;
//         flex-wrap: wrap;
//         height: 700px;
//         .chat_box_left {
//             width: 20%;
//             padding-right: 15px;
//             height: 700px;
//             overflow: hidden;
//             position: relative;
//             .search_box_wrap {
//                 padding: 0px 0px 15px;
//                 .search_box {
//                     position: relative;
//                     input {
//                         height: 46px;
//                         padding-left: 15px;
//                     }
//                     button {
//                         position: absolute;
//                         top: 0;
//                         right: 0;
//                     }
//                 }
//             }
//             .user_list {
//                 ul {
//                     overflow: auto;
//                     height: calc(100% - 61px);
//                     width: calc(100% - 15px);
//                     position: absolute;
//                     -ms-overflow-style: none;  
//                     scrollbar-width: none;  
//                     &::-webkit-scrollbar {
//                         display: none;
//                     }
//                     li {
//                         .user_list_inner {
//                             display: flex;
//                             position: relative;
//                             padding: 20px;
//                             padding-left: 10px;
//                             margin-bottom: 10px;
//                             background: #fff;
//                             border-radius: 5px;
//                             .unseen_notification {
//                                 position: absolute;
//                                 right: 0;
//                                 top: 0;
//                                 width: 20px;
//                                 height: 20px;
//                                 background: rgb(49 46 129);
//                                 border-radius: 50%;
//                                 color: #fff;
//                                 display: flex;
//                                 align-items: center;
//                                 justify-content: center;
//                                 font-size: 10px;
//                             }
//                             .img_wrap {
//                                 .img {
//                                     overflow: hidden;
//                                     border-radius: 50%;
//                                     margin-right: 10px;
//                                     width: 30px;
//                                     height: 30px;
//                                     top: 4px;
//                                     position: relative;
//                                     img {
//                                         overflow: hidden;
//                                         width: 30px;
//                                         height: 30px;
//                                     }
//                                 }
//                             }
//                             .text {
//                                 h3 {
//                                     font-size: 14px;
//                                     font-weight: 500;
//                                     padding-bottom: 5px;
//                                 }
//                                 .chat_text {
//                                     p {
//                                         margin-bottom: 0;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                         -webkit-line-clamp: 1;
//                                         -webkit-box-orient: vertical;
//                                         white-space: pre-wrap;
//                                         display: -webkit-inline-box;
//                                     }
//                                 }
//                                 .time {
//                                     position: absolute;
//                                     right: 15px;
//                                     top: 23px;
//                                     font-size: .75rem;
//                                     color: rgb(148 163 184);
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//         .chat_box_right {
//             width: 80%;
//             padding-left: 15px;
//             background: #fff;
//             height: 700px;
//             position: relative;
//             .user_list_inner {
//                 display: flex;
//                 position: relative;
//                 padding: 13px;
//                 padding-left: 0;
//                 margin-bottom: 10px;
//                 background: #fff;
//                 border-radius: 5px;
//                 .unseen_notification {
//                     position: absolute;
//                     right: 0;
//                     top: 0;
//                     width: 20px;
//                     height: 20px;
//                     background: rgb(49 46 129);
//                     border-radius: 50%;
//                     color: #fff;
//                     display: flex;
//                     align-items: center;
//                     justify-content: center;
//                     font-size: 10px;
//                 }
//                 .img_wrap {
//                     .img {
//                         overflow: hidden;
//                         border-radius: 50%;
//                         margin-right: 10px;
//                         width: 40px;
//                         height: 40px;
//                         position: relative;
//                         img {
//                             overflow: hidden;
//                             width: 40px;
//                             height: 40px;
//                         }
//                     }
//                 }
//                 .text {
//                     h3 {
//                         font-size: 14px;
//                         font-weight: 500;
//                         padding-bottom: 5px;
//                     }
//                     .chat_text {
//                         p {
//                             margin-bottom: 0;
//                             overflow: hidden;
//                             text-overflow: ellipsis;
//                             -webkit-line-clamp: 1;
//                             -webkit-box-orient: vertical;
//                             white-space: pre-wrap;
//                             display: -webkit-inline-box;
//                         }
//                     }
//                     .time {
//                         position: absolute;
//                         right: 15px;
//                         top: 23px;
//                         font-size: .75rem;
//                         color: rgb(148 163 184);
//                     }
//                 }
//             }
//             .left-nav-bottom-wrap {
//                 padding: 0px 0px 50px;
//                 display: flex;
//                 align-items: center;
//                 flex-direction: column;
//                 border-top: 1px solid #d6dee2;
//                 font-size: 15px;
//                 position: absolute;
//                 bottom: 0;
//                 left: 0;
//                 width: 100%;
//                 height: calc(100% - 70px);
//                 .right_chat_box {
//                     width: 100%;
//                     overflow: auto;
//                     -ms-overflow-style: none;  
//                     scrollbar-width: none;  
//                     &::-webkit-scrollbar {
//                         display: none;
//                     }
//                     .chat_box_inner {
//                         ul {
//                             display: flex;
//                             align-items: flex-end;
//                             justify-content: center;
//                             flex-direction: column;
//                             li {
//                                 width: 100%;
//                                 display: flex;
//                                 justify-content: flex-end;
//                                 padding: 5px;
//                                 &.left {
//                                     justify-content: flex-start;
//                                     .chat_box_inline {
//                                         padding-left: 5px;
//                                         display: flex;
//                                         align-items: flex-start;
//                                         justify-content: flex-start;
//                                         .main_chat_text {
//                                             background: rgb(241 245 249);
//                                             order: 2;
//                                             color: rgb(30, 41, 59);
//                                         }
//                                         .right_arrow {
//                                             order: 1;
//                                             margin-right: 10px;
//                                         }
//                                     }
                                    
//                                 }
//                                 .chat_box_inline {
//                                     position: relative;
//                                     padding-left: 15px;
//                                     width: 100%;
//                                     position: relative;
//                                     padding-right: 5px;
//                                     width: 50%;
//                                     display: flex;
//                                     align-items: flex-start;
//                                     justify-content: flex-end;
//                                     .conversation_name {
//                                         display: flex;
//                                         align-items: center;
//                                         justify-content: center;
//                                         width: 100%;
//                                         flex-direction: column;
//                                         p {
//                                             margin-bottom: 5px;
//                                         }
//                                         a {
//                                             background-color: #6f7782;
//                                             color: #FFFFFF;
//                                             padding: 2px 11px;
//                                             border-radius: 15px;
//                                             font-size: 12px;
//                                         }
//                                     }
//                                     .right_arrow {
//                                         order: 2;
//                                         .img {
//                                             overflow: hidden;
//                                             border-radius: 50%;
//                                             margin-left: 10px;
//                                             width: 40px;
//                                             height: 40px;
//                                             position: relative;
//                                             img {
//                                                 overflow: hidden;
//                                                 width: 40px;
//                                                 height: 40px;
//                                             }
//                                         }
//                                     }
//                                     .main_chat_text {
//                                         background-color: rgb(49 46 129);
//                                         padding: 9px 15px;
//                                         border-radius: 5px;
//                                         word-wrap: break-word;
//                                         color: #fff;
//                                         font-size: 14px;
//                                         line-height: 20px;
//                                         .time {
//                                             opacity: 0.8;
//                                             font-size: 12px;
//                                             padding-top: 2px;
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//                 .chat_input_box {
//                     position: absolute;
//                     left: 0;
//                     bottom: 0;
//                     width: 100%;
//                     border-top: 1px solid #e2e8f099;
//                     input {
//                         width: 100%;
//                         border: none;
//                         height: 48px;
//                         padding: 2px 50px 2px 20px;
//                         &:focus {
//                             outline: 0;
//                             box-shadow: none;
//                         }
//                     }
//                     button {
//                         position: absolute;
//                         right: 10px;
//                         top: 12px;
//                         border: none;
//                         background: #fff;
//                         &:focus {
//                             outline: 0;
//                             box-shadow: none;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }