@use "../base" as *;
@use "../mixin" as *;
  /* Generic */
  .login_page {

  }
  
//   /**/
//   .login_wrap {
//     position: relative;
//     width: 100%;
//     min-width: 800px;
//     /* min-height: 600px; */
//     height: 600px;
//     padding: 25px;
//     background-color: #ecf0f3;
//     box-shadow: 10px 10px 10px #d1d9e6, -10px -10px 10px #f9f9f9;
//     border-radius: 12px;
//     overflow: hidden;
//     @include mobile {
//         max-width: 100%;
//         min-width: 90%;
//         margin: 0 auto;
//         height: auto;
//         padding: 0;
//     }
//     .login-container {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         position: absolute;
//         top: 0;
//         width: 400px;
//         height: 100%;
//         padding: 25px;
//         background-color: #ecf0f3;
//         transition: 1.25s;
//         left: 46%;
//         @include mobile {
//             left: 0;
//             position: relative;
//             width: 100%;
//         }
//     }
//   }
  

  
//   .form {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     flex-direction: column;
//     width: 100%;
//     height: 100%;
//   }
//   .form__icon {
//     object-fit: contain;
//     width: 30px;
//     margin: 0 5px;
//     opacity: 0.5;
//     transition: 0.15s;
//   }
//   .form__icon:hover {
//     opacity: 1;
//     transition: 0.15s;
//     cursor: pointer;
//   }
//   .form__input {
//     width: 350px;
//     height: 40px;
//     margin: 6px 0;
//     padding-left: 15px;
//     font-size: 13px;
//     letter-spacing: 0.15px;
//     border: none;
//     outline: none;
//     font-family: "Montserrat", sans-serif;
//     background-color: #ecf0f3;
//     transition: 0.25s ease;
//     border-radius: 8px;
//     box-shadow: inset 2px 2px 4px #d1d9e6, inset -2px -2px 4px #f9f9f9;
//     &::placeholder {
//         color: #000;
//     }
//     @include mobile {
//         width: 300px;
//     }
//   }
//   .form__input:focus {
//     box-shadow: inset 4px 4px 4px #d1d9e6, inset -4px -4px 4px #f9f9f9;
//   }
//   .form__span {
//     margin-top: 30px;
//     margin-bottom: 12px;
//   }
//   .form__link {
//     color: #181818;
//     font-size: 15px;
//     margin-top: 25px;
//     border-bottom: 1px solid #a0a5a8;
//     line-height: 2;
//   }
  
//   .title {
//     font-size: 34px;
//     font-weight: 700;
//     line-height: 3;
//     color: #181818;
//     @include mobile {
//         font-size: 25px;
//     }
//   }
  
//   .description {
//     font-size: 14px;
//     letter-spacing: 0.25px;
//     text-align: center;
//     line-height: 1.6;
//   }
  
//   .button {
//     width: 180px;
//     height: 50px;
//     border-radius: 5px;
//     margin-top: 50px;
//     font-weight: 700;
//     font-size: 14px;
//     letter-spacing: 1.15px;
//     box-shadow: 8px 8px 16px #d1d9e6, -8px -8px 16px #f9f9f9;
//     border: none;
//     outline: none;
//     @include mobile {
//         margin-top: 20px;
//         width: 140px;
//         height: 40px;
//         margin-bottom: 30px;
//     }
//   }
  
//   /**/
//   .a-container {
//     z-index: 100;
//     left: calc(100% - 600px );
//   }
  
//   .b-container {
//     left: calc(100% - 600px );
//     z-index: 0;
//   }
  
//   .switch {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     position: absolute;
//     top: 0;
//     left: 0;
//     height: 100%;
//     width: 350px;
//     padding: 50px;
//     z-index: 200;
//     transition: 1.25s;
//     background-color: #ecf0f3;
//     overflow: hidden;
//     box-shadow: 4px 4px 10px #d1d9e6, -4px -4px 10px #f9f9f9;
//     @include mobile {
//         display: none;
//     }
//   }
//   .switch__circle {
//     position: absolute;
//     width: 500px;
//     height: 500px;
//     border-radius: 50%;
//     background-color: #ecf0f3;
//     box-shadow: inset 8px 8px 12px #d1d9e6, inset -8px -8px 12px #f9f9f9;
//     bottom: -60%;
//     left: -60%;
//     transition: 1.25s;
//   }
//   .switch__circle--t {
//     top: -30%;
//     left: 60%;
//     width: 300px;
//     height: 300px;
//   }
//   .switch__container {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     flex-direction: column;
//     position: absolute;
//     width: 400px;
//     padding: 50px 55px;
//     transition: 1.25s;
//   }
//   .switch__button {
//     cursor: pointer;
//   }
//   .switch__button:hover {
//     box-shadow: 6px 6px 10px #d1d9e6, -6px -6px 10px #f9f9f9;
//     transform: scale(0.985);
//     transition: 0.25s;
//   }
//   .switch__button:active, .switch__button:focus {
//     box-shadow: 2px 2px 6px #d1d9e6, -2px -2px 6px #f9f9f9;
//     transform: scale(0.97);
//     transition: 0.25s;
//   }
  
//   /**/
//   .is-txr {
//     left: calc(100% - 400px );
//     transition: 1.25s;
//     transform-origin: left;
//   }
  
//   .is-txl {
//     left: 0;
//     transition: 1.25s;
//     transform-origin: right;
//   }
  
//   .is-z200 {
//     z-index: 200;
//     transition: 1.25s;
//   }
  
//   .is-hidden {
//     visibility: hidden;
//     opacity: 0;
//     position: absolute;
//     transition: 1.25s;
//   }
  
//   .is-gx {
//     animation: is-gx 1.25s;
//   }
  
//   @keyframes is-gx {
//     0%, 10%, 100% {
//       width: 400px;
//     }
//     30%, 50% {
//       width: 500px;
//     }
//   }



.login_wrap {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #121212;
    color: #a0a5a8;
    min-width: auto;
    .login_wrap_inner{
        background-color: #202020;
        height: 450px;
        width: 400px;
        position: relative;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 8px;
        box-shadow: 0 20px 25px rgba(0,0,0,0.35);
        padding: 0 50px;
        .login_logo {
            @include d_flex_center;
            flex-direction: column;
            padding: 30px 0px 50px;
        }
    }
    .login_wrap_inner *{
        border: none;
        outline: none;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.5px;
    }
    input{
        display: block;
        height: 45px;
        width: calc(100% - 100px);
        position: absolute;
        background-color: #4d4d4d;
        color: #ffffff;
        padding: 0 15px;
        font-weight: 300;
        border-radius: 5px;   
        transition: 0.5s all;
    }
    label{
        display: inline-block;
        color: #fff;
        cursor: pointer;
        font-size: 16px;
        position: absolute;
        transition: 0.5s all;
        width: calc(100% - 100px);
    }
    #username{
        bottom: 265px;
    }
    .has_error.username {
        bottom: 245px;
        color: #E24E4D;
        position: absolute;
    }
    label[for = "username"]{
        bottom: 320px;
        // transform: translateY(-45px);
    }
    #password{
        bottom: 165px;
        // transform: translateY(-45px);
    }
    .has_error.password {
        bottom: 145px;
        color: #E24E4D;
        position: absolute;
    }
    label[for = "password"]{
        bottom: 220px;
    }
    ::placeholder{
        color: transparent;
    }
    .my-style::placeholder{
        color: #a5a5a5;
    }
    button{
        width: 300px;
        position: absolute;
        bottom: 75px;
        padding: 15px 0;
        background-color: #121212;
        color: #ffffff;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 15px;
    }
}