.container {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
}

.row_gutters {
    margin-right: 0;
    margin-left: 0;
}

.row_gutters >.col,
.row_gutters >[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}

.col_1,
.col_2,
.col_3,
.col_4,
.col_5,
.col_6,
.col_7,
.col_8,
.col_9,
.col_10,
.col_11,
.col_12,
.col{
    position: relative;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
}

.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}

.col_auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.col_1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}

.col_2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}

.col_3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.col_4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.col_5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}

.col_6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col_7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.col_8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

.col_9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.col_10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}

.col_11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
}

.col_12 {
    flex: 0 0 100%;
    max-width: 100%;
}