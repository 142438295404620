
@use "../base" as *;
@use "../mixin" as *;

.Item_sort .onleft_acc_arrow {
    width: 21px;
    height: 21px;
    float: left;
    position: absolute;
    left: 4px;
    font-size: 23px;
}

.Item_sort .onleft_acc_arrow:after {
    float: left !important;
    content: "+";
    position: absolute;
    left: 4px;
    top: -6px;
    background: none;
    color: $color_main;
    font-weight: 300;
}

.Item_sort .onleft_acc_arrow.open:after {
    float: left !important;
    content: "";
    font-size: 30px;
    width: 11px;
    height: 1px;
    background: $color_main;
    position: absolute;
    left: 5px;
    top: 7px;
}

.Item_sort .accordion_heading {}

.Item_sort li {}

.Item_sort li span {
    display: block;
    margin-right: 30px;
}

.has_subclass_acc {
    width: 100%;
    padding: 8px 7px;
    margin-bottom: 8px;
    text-transform: uppercase;
    cursor: pointer;
    padding-left: 30px;
    position: relative;
    background: $dropdown_bg;
    color: #fff;
}

.Item_sort li.no_subclass_acc {
    // border: 2px dotted $dropdown_bg;
    // opacity: .7;
    background: $dropdown_bg;
    width: 100%;
    padding: 8px 7px;
    margin-bottom: 8px;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    padding-left: 30px;
    color: #fff;
}

.Item_sort li.no_subclass_acc .onleft_acc_arrow:after {
    float: left !important;
    content: "";
    font-size: 30px;
    width: 11px;
    height: 1px;
    background: $color_main;
    position: absolute;
    left: 5px;
    top: 8px;
}

.Item_sort li {
    position: relative;
}

.Item_sort li:after {
    position: absolute;
    right: 4px;
    top: 7px;
    width: 17px;
    height: 17px;
    content : "\eb28";
    background-size: contain;
    cursor: move;
    font-family: 'LineIcons';
    color: #fff;
}

.Item_sort li .sub_accordion li {
    background: #4c4c4c;
    width: 100%;
    padding: 8px 7px;
    margin-bottom: 8px;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    border : none;
    color: #fff;
}

.Item_sort li .sub_accordion {
    padding-left: 21px;
}

.item_short_wrap {
    display: flex;
    .Item_sort {
        min-width: 480px;
        width: 500px;
        max-width: 100%;
    }
    .Item_sort_child {
        .has_subclass_acc {
            background: #979797;
        }
    }
    .add_new_cat {
        width: 665px;
        // display: none;
        position: relative;
        .add_new_inner {
            padding: 16px;
            border: 1px solid $border_color;
            border-radius: 3px;
        }
    }
}

.pa_0 {
    padding: 0!important;
}
.transparent_bg {
    background: transparent!important;
}