@use "../base" as *;
@use "../mixin" as *;

#left_nav{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    flex: 0 0 auto;
    width: 270px;
    transition: width .3s;
    border-right: 1px solid rgba(211, 211, 211, 0.7);
    z-index: 1021;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 40px;
    @include belowIpadpro {
        position: absolute;
    }
    .vendor_logo {
        padding: 30px 0px;
        border-bottom: 1px solid rgba(211, 211, 211, 0.7);
        margin-bottom: 25px;
        @include d_flex_center;
    }
}
.brand {
    padding: 0 10px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 230px;
    // border-bottom: 1px solid #DEE2E6;
    img {
        width: 100px;
    }
    a {
        font-size: 20px;
        color: $color_main;
        font-weight: bold;
        text-transform: uppercase;
    }
}
.left_nav_list {
    font-size: 14px;
    overflow: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
    height: 100%;
    &::-webkit-scrollbar {
        width: 0px;
    }
    &::-webkit-scrollbar-track {
        background: var(--scrollbarBG);
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--thumbBG) ;
        border-radius: 0px;
        border: 0px solid var(--scrollbarBG);
    }
}

ul.left_nav_list>li {
    padding: 0;
    position: relative;
    // &.open {
    //     background: #f4f5fa;
    // }
}
ul.left_nav_list>li .left_f_list {
    padding: 14px 20px;
    position: relative;
    transition: all .3s;
    font-weight: 600;
    // border-bottom: 1px solid $border_color;
    display: flex;
    align-items: center;
    font-size: 14px;
    text-transform: uppercase;
    &.active {
        background: rgba(191, 191, 191, 0.3);
    }
    .left_img {
        margin-right: 10px;
        i  {
            font-size: 20px;
        }
        img {
            width: 16px;
        }
    }
    > a {
        padding: 0;
        display: flex;
        align-items: center;
    }
}

ul.left_nav_list>li .left_f_list:after {
    font-family: 'LineIcons';
    content: "\eb11";
    position: absolute;
    right: 10px;
    top: 0px;
    background: none;
    transition: all .2s;
    width: 25px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #000;
}

ul.left_nav_list>li .left_f_list.open:after {
    transform: rotate(180deg);
    transition: all .2s;
    right: 10px;
    top: 0px;
}

ul.left_nav_list>li .left_nav_level2{
    position: relative;
    transition: all .3s;
    font-weight: 500;
    padding: 0!important;
    .left_f_list_level2{
        padding-left: 56px;
        // border-bottom: 1px solid $border_color;
        position: relative;
        font-weight: 400;
        > span {
            padding: 6px 0px;
            display: flex;
            position: relative;
            &:before {
                position: absolute;
                left: -12px;
                top: 50%;
                width: 5px;
                height: 5px;
                background: $color_main;
                border-radius: 50%;
                content: "";
                transform: translateY(-50%);
            }
        }
    }
    .sub_accordion {
        li {
            padding-left: 70px;
            font-weight: 400;
        }
    }
}
ul.left_nav_list>li .left_nav_level3 {
    position: relative;
    transition: all .3s;
    font-weight: 400;
    padding: 0!important;
    .left_f_list_level3 {
        @include transition;
        padding-left: 70px;
        // border-bottom: 1px solid $border_color;
        position: relative;
        > span {
            padding: 7px 0px;
            display: flex;
            position: relative;
            // &:before {
            //     position: absolute;
            //     left: -12px;
            //     top: 50%;
            //     width: 5px;
            //     height: 5px;
            //     background: $color_main;
            //     border-radius: 50%;
            //     content: "";
            //     transform: translateY(-50%);
            // }
        }
    }
    .sub_accordion {
        li {
            padding-left: 85px;
        }
    }
}
ul.left_nav_list>li .left_f_list_level2:after ,
ul.left_nav_list>li .left_f_list_level3:after {
    font-family: 'LineIcons' !important;
    content: "\eb0d";
    font-size: 30px;
    font-size: 9px;
    position: absolute;
    right: 10px;
    top: 0px;
    height: 100%;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    font-weight: 100;
    transition: all .2s;
}

ul.left_nav_list>li .left_f_list_level2.open:after ,
ul.left_nav_list>li .left_f_list_level3.open:after {
    transform: rotate(90deg);
    transition: all .2s;
    right: 10px;
    top: 0px;
}
.sub_accordion {
    display: none;
    // background: #000;form_global
    &.first_level {
        // padding-top: 5px;
        // padding-bottom: 5px;
    }
    li {
        @include transition;
        padding-left: 56px;
        // border-bottom: 1px solid $border_color;
        a {
            padding: 6px 0px;
            display: flex;
            font-size: 13px;
            text-transform: uppercase;
        }
    }
    li.left_nav_level2,
    li.left_nav_level3 {
        // border-bottom: 0px solid #414143;
        @include transition;
    }
}

// .left_f_list:hover ,
.left_f_list_level2:hover > div,
.left_f_list_level3:hover > div{
    // background: $hover_color;
    // @include transition;
    // a {
    //     color: #fff!important;
    //     @include transition;
    // }
    // span {
    //     color: #fff!important;
    //     @include transition;
    // }
    // i {
    //     color: #fff!important;
    //     @include transition;
    // }
    // &:after {
    //   color: #fff!important;
    //   @include transition;  
    // }
}
// .sub_accordion > li:hover {
//     @include transition;
//     background: $hover_color;
//     > a {
//         color: #fff!important;
//         @include transition;
//     }
//     > span {
//         color: #fff!important;
//         @include transition;
//     }
//     &:after {
//       color: #fff!important;
//       @include transition;  
//     }
// }
.sub_accordion2 > li{
    padding-left: 65px;
}
.sub_accordion3 > li{
    padding-left: 70px;
}
// .left_nav_list > li:hover > div,
// .first_level > li:hover
// .sub_accordion2 > li:hover ,
// .sub_accordion3 > li:hover {
//     background: $hover_color;
//     @include transition;
// }
.left_nav_list .active {
    // background: $dropdown_bg; 
    // color: $color_white!important;
}
.sub_accordion.open {
    display: block!important;
}
.sub_accordion.current {
    display: block!important;
}
.no_arrow {
    &:after {
        display: none!important;
    }
}