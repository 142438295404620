
@use "../base" as *;
@use "../mixin" as *;

/*------------------------------
    HEADER AREA
--------------------------------*/
.header_area {
    border-bottom: 1px solid rgba(186,186,186, .3);
    background: #141414;
    position: fixed;
    width: 100%;
    z-index: 20;
    .col-md-12 {
        width: 100%;
    }
    &.header_type_2 {
        // border-bottom: 1px solid rgba(95,95,95,0.3);
        // border-bottom: 1px solid #5F5F5F;
        border-bottom: 1px solid transparent;
        .header_main_wrap {
            .header_right_menu {
                position: relative;
                ul {
                    display: flex;
                    li {
                        display: flex;
                        align-self: stretch;
                        &.logged_in_link {
                            // background: #fff;
                            &.first-type {
                                a {
                                    padding-left: 25px;
                                    @include mobile {
                                        padding-left: 12px;
                                    }
                                }
                            }
                            a {
                                padding: 20px 15px;
                                border-left: 0px solid transparent;
                                position: relative;
                                @include belowIpadpro {
                                    padding: 17px 12px;
                                    border-left: 1px solid rgba(95, 95, 95, 0.3);
                                }
                                span {
                                    position: absolute;
                                    right: 5px;
                                    top: 8px;
                                    background: #000;
                                    color: #fff;
                                    padding: 2px;
                                    border-radius: 50%;
                                    width: 20px;
                                    height: 20px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 10px;
                                }
                                img {
                                }
                            }
                        }
                        a {
                            // border-left : 1px solid rgba(186,186,186, .3);
                            display: flex;
                            padding: 20px 40px;
                            color: $themeSubColor;
                            text-transform: uppercase;
                            align-items: center;
                            @include desktopXl {
                                padding: 20px 15px;
                            }
                            @include desktopLg {
                                padding: 20px 20px;
                            }
                            @include mobile {
                                padding: 15px 15px;
                            }
                        }
                        &:last-child {
                            a {
                                // @include mobile {
                                //     padding-right: 1px;
                                // }
                            }
                        }
                    }
                }
            }
        }
    }
    .header_main_wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .header_left {
            display: flex;
            align-items: center;
            flex: 1;
            .logo {
                display: flex;
                align-items: center;
                align-self: stretch;
                border-right : 1px solid rgba(186,186,186, .3);
                padding-left: 25px;
                padding-right: 10px;
                width: 270px;
                min-height: 60px;
                .hamburger_menu {
                    margin-right: 10px;
                    @include desktop {
                        display: none;
                    }
                    .toggle {
                        position: relative;
                        width: 30px;
                        height: 30px;
                        border-radius: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        overflow: hidden;
                        span {
                            position: absolute;
                            width: 25px;
                            height: 2px;
                            background: #fff;
                            border-radius: 10px;
                            transition: 0.5s;
                        }
                    }
                    .toggle span:nth-child(1) {
                        transform: translateY(-7px);
                        width: 25px;
                        left: 3px;
                    }
                    .toggle.active span:nth-child(1) {
                        width: 25px;
                        transform: translateY(0) rotate(45deg);
                        transition-delay: 0.125s;
                    }
                    .toggle span:nth-child(2) {
                        transform: translateY(7px);
                        width: 15px;
                        left: 3px;
                    }
                    .toggle.active span:nth-child(2) {
                        width: 25px;
                        transform: translateY(0) rotate(315deg);
                        transition-delay: 0.25s;
                    }
                    .toggle.active span:nth-child(3) {
                        transform: translateX(60px);
                    }
                }
                
                img {
                    // w
                    max-width: 180px;
                }
            }
            .header_menu {
                display: flex;
                border-right : 1px solid rgba(186,186,186, .3);
                @include ipadpro {
                    display: none;
                }
                ul {
                    display: flex;
                    align-items: center;
                    li {
                        display: flex;
                        position: relative;
                        &:last-child {
                            &:after {
                                display: none;
                            }
                        }
                        &:after {
                            position: absolute;
                            content: "";
                            right: 0;
                            top: 50%;
                            width: 1px;
                            height: 12px;
                            background:  rgba(186,186,186, .3);
                            transform: translateY(-50%);
                        }
                        &.active {
                            border-bottom: 3px solid #fff;
                        }
                        a {
                            display: flex;
                            padding: 20px 40px;
                            color: $themeSubColor;
                            text-transform: uppercase;
                            @include desktopXl {
                                padding: 20px 15px;
                            }
                            @include desktopLg {
                                padding: 20px 20px;
                            }
                        }
                    }
                }
            }
            .header_search {
                flex: 1;
                padding: 20px 30px;
                display: flex;
                @include ipadpro {
                    display: none;
                }
                input {
                    background: transparent;
                    border: none;
                    box-shadow: none;
                    padding-left: 10px;
                    color: $themeSubColor;
                    width: 100%;
                    flex: 1;
                    &:focus {
                        outline: 0;
                        box-shadow: none;
                    }
                }
            }
        }
        .header_right_menu {
            display: flex;
            align-self: stretch;
            // border-left : 1px solid rgba(186,186,186, .3);
            ul {
                display: flex;
                li {
                    display: flex;
                    align-self: stretch;
                    &.vendor_logo {
                        border-right : 1px solid rgba(186,186,186, .3);
                        margin-right: 20px;
                    }
                    .dropdown {
                        display: flex;
                        padding: 14px 15px;
                        button {
                            position: relative;
                            span {
                                border-radius: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                top: 46%;
                                right: 46%;
                                margin: -19px -22px 0 0;
                                position: absolute;
                                z-index: 1;
                                width: 22px;
                                height: 22px;
                                background: #fff;
                                color: #000;
                                border: 1px solid #fff;
                                font-size: 12px;
                                line-height: 16px;
                                text-align: center;
                                font-weight: 500;
                            }
                        }
                        .dropdown_menu  {
                            ul {
                                li {
                                    a {
                                        padding: 7px 15px;
                                        color: #000;
                                    }
                                }
                            }
                        }
                    }
                    a {
                        // border-left : 1px solid rgba(186,186,186, .3);
                        display: flex;
                        padding: 20px 40px;
                        color: $themeSubColor;
                        text-transform: uppercase;
                        @include desktopXl {
                            padding: 20px 15px;
                        }
                        @include desktopLg {
                            padding: 20px 20px;
                        }
                        @include mobile {
                            padding: 15px 15px;
                        }
                    }
                    &:last-child {
                        a {
                            
                        }
                    }
                }
            }
        }
    }

    .header_search_details {
        position: absolute;
        top: 100%;
        background: #fff;
        padding: 15px;
        padding-top: 25px;
        width: 100%;
        display: none;
        &.open {
            display: block;
        }
        .close_search {
            position: absolute;
            top: 10px;
            right: 10px;
            border-radius: 50%;
            border: 1px solid #000;
            display: flex;
            width: 25px;
            height: 25px;
            padding: 2px;
            .close_icon {
                width: 17px;
                height: 17px;
                top: 3px;
                right: 3px;
            }
            .close_icon::before, .close_icon::after {
                content: '';
                position: absolute;
                height: 2px;
                width: 100%;
                top: 50%;
                left: 0;
                margin-top: -1px;
                background: #000;
                height: 1px;
            }
        }
        .header_search_details_inner {
            max-width: 800px;
            margin: 0 auto;
            width: 100%;
            .search_heading {
                font-size: 20px;
                padding-bottom: 10px;
                color: #000;
                font-weight: 500;
                border-bottom: 1px solid rgba(186, 186, 186, 0.3);
                margin-bottom: 20px;
            }
            .product_main_wrap {
                overflow: auto;
                max-height: 400px;
                &::-webkit-scrollbar {
                    width: 3px;
                }
                
                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }
                
                &::-webkit-scrollbar-thumb {
                    background: #888;
                }
                
                &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
                .inner {
                    padding-bottom: 20px;
                    width: 16.6667%;
                    overflow: hidden;
                    @include mobile {
                        width: 33.3333%;
                    }
                    .inner_wrap {
                        display: flex;
                        flex-wrap: wrap;
                        .search_p_img_wrap {}
                    }
                }
            }
        }
    }
}
