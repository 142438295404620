
@use "../base" as *;
.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid transparent;
    position: relative;
    outline: 0;
    box-shadow: none;
    border-radius: 2px;
    text-transform: capitalize;
    white-space: nowrap;
    padding: 6px 12px;
    // height: 34px;
    transition: all .3s ease-in-out;
    // box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    &.has_border_radius {
        border-radius: 4px;
    }
    &.has_border_radius_15 {
        border-radius: 15px;
    }
    &:hover {
        transition: all .3s ease-in-out;
        box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.1), 0 4px 23px 0 rgba(0, 0, 0, 0.1), 0 8px 10px -5px rgba(0, 0, 0, 0.1);
    }
    &:focus {
        outline: 0;
        box-shadow: none;
        box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.2);
    }
    &:disabled {
        outline: 0;
        box-shadow: none;
        cursor: not-allowed;
        background: #dddddd!important;
        border: 1px solid #dddddd!important;
        &:hover {
            background: #dddddd!important;
            border: 1px solid #dddddd!important;  
        }
    }
    &.btn_circle {
        border-radius: 50%;
        width: 25px;
        height: 25px;
        i {
            font-size: 10px;
        }
    }
}
[class^="btn_with_opacity"] {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid transparent;
    position: relative;
    outline: 0;
    box-shadow: none;
    border-radius: 2px;
    text-transform: capitalize;
    white-space: nowrap;
    padding: 6px 12px;
    transition: all .3s ease-in-out;
    opacity: .5;
    // box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    &:hover {
        transition: all .3s ease-in-out;
        box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.2);
    }
    &:focus {
        outline: 0;
        box-shadow: none;
        box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.2);
    }
    &:disabled {
        outline: 0;
        box-shadow: none;
        cursor: not-allowed;
    }
    &.btn_circle {
        border-radius: 50%;
        width: 25px;
        height: 25px;
        i {
            font-size: 10px;
        }
    }
}
button,
button:hover,
button:focus {
    outline: 0;
}
.btn_transparent {
    background: transparent;
    border-color: $color_black;
    color: $color_black;
    &:hover {
        background: transparent;
        color: $color_black;
    }
    &:focus {
        box-shadow: none;
    }
}
.btn_black {
    background: $color_black;
    border-color: $color_black;
    color: $color_white;
    &:hover {
        background: $color_black;
        color: $color_white;
    }
}
.btn_theme {
    background: $themeColor;
    border-color: $themeColor;
    color: $dropdown_bg;
    &:hover {
        background: $themeColor;
        color: $dropdown_bg;
    }
}
.btn_white {
    background: $color_white;
    border-color: $color_white;
    color: $dropdown_bg;
    &:hover {
        background: $color_white;
        color: $dropdown_bg;
    }
}
.btn_primary {
    background: $color_primary;
    border-color: $color_primary;
    color: $color_white;
    &:hover {
        background: $color_primary_hover;
        color: $color_white;
    }
}
.btn_gradient_primary {
    background-image: linear-gradient(45deg, #002966, #007099);
    border-color: #002966;
    color: $color_white;

}
.btn_info {
    background: $color_info;
    border-color: $color_info;
    color: #fff;
    &:hover {
        background: $color_info_hover;
        color: #fff;
    }
}

.btn_gradient_info {
    background: linear-gradient(45deg, #0052cc, #00baff);
    border-color: #0052cc;
    color: $color_white;
}

.btn_success {
    background: $color_success;
    border-color: $color_success;
    color: #fff;
    &:hover {
        background: $color_success_hover;
        color: #fff;
    }
}

.btn_gradient_success {
    background: linear-gradient(45deg, #04a08b, #00baff);
    border-color: #04a08b;
    color: $color_white;
}

.btn_warning {
    background: $color_warning;
    border-color: $color_warning;
    color: $color_white;
    &:hover {
        background: $color_warning_hover;
        color: $color_white;
    }
}

.btn_gradient_warning {
    background: linear-gradient(45deg, #ff9920, #00baff);
    border-color: #ff9920;
    color: $color_white;
}

.btn_danger {
    background: $color_danger;
    border-color: $color_danger;
    color: #fff;
    &:hover {
        background: $color_danger;
        color: #E24E4D;
        color: #fff;
    }
}

.btn_gradient_danger {
    background: linear-gradient(45deg, #ff562f, #00baff);
    border-color: #ff562f;
    color: $color_white;
}

.btn_gren {
    background: $color_green;
    border-color: $color_green;
    color: #1FB346;
    &:hover {
        background: $color_danger_hover;
        color: #1FB346;
    }
}

// .btn_black {
//     background: $color_black;
//     border-color: $color_black;
//     color: $color_white;
//     &:hover {
//         background: $color_black_hover;
//         color: $color_white;
//     }
// }
.btn_secondary {
    background: $color_secondary;
    border-color: $color_secondary;
    color: $color_white;
    &:hover {
        background: $color_secondary_hover;
        color: $color_white;
    }
}


.btn_xs {
    padding: 2px 6px;
    font-size: 12px;
}
.btn_sm {
    padding: 5px 15px;
    font-size: 12px;
}
.btn_md {
    padding: 7px 21px;
    font-size: 14px;
}
.btn_lg {
    padding: 10px 30px;
    font-size: 16px;
}
.btn_xl {
    padding: 15px 45px;
    font-size: 20px;
}


.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 10px;
    button {
        background: transparent;
        border: none;
        &:focus {
            outline: 0;
            box-shadow: none;
        }
    }
}

.upload-btn-wrapper input:hover button.btn_upload {
    background: #16325c;
    color: #fff;
    transition: all .3s;
}

.btn_upload {
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    padding: 4px 15px;
    border: 1px solid #fff;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    cursor: pointer;
    transition: all .3s;
    &:hover {
        background: #16325c;
        color: #fff;
        transition: all .3s;
    }
}

.upload-btn-wrapper input[type=file] {
    font-size: 7px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    height: 24px;
    cursor: pointer;
}
