

.categories_row {
    padding-right: 60%;
    .last_category{
      border: 1px solid rgb(223, 224, 225);
      cursor: pointer;
      font-size: 15px;
      //margin-bottom: 10px;
      padding: 9px 15px;
      position: relative;
    }

    .accordion {
        margin-bottom: 10px;
        .accordion_heading  {
            position: relative;
            .category_actions {
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
                display: flex;
                align-items: center;
                justify-content: center;
                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        // -webkit-filter: invert(100%); /* Safari/Chrome */
                        // filter: invert(100%);
                    }
                    i {
                        // color: #fff;
                    }
                }
            }
            .accordion_heading_inner {
                // background: #000;
                // color: #fff;
                border-radius: 2px;
                &.left {
                    padding-left: 35px;
                }
                &:before {
                    font-family: 'LineIcons' !important;
                    position: absolute;
                    content: "\eab8";
                    transition: all .3s;
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 13px;
                    // color: #fff;
                } 
                &.active:before {
                    transition: all .3s;
                    content: "\eabc";
                }
                &.left:before {
                    transition: all .3s;
                    right: auto;
                    left: 10px;
                }
            }
            .last_category {
                // background: #000;
                // color: #fff;
                border-radius: 2px;
            }
        }
        .accordion_body {
            border: none;
            padding: 10px;
        }
    }
}