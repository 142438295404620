/*-------------------------
    FONT WEIGHT
-------------------------*/

.fw_normal {
    font-weight: normal!important;
}
.fw_regular {
    font-weight: 400!important;
}
.fw_500 {
    font-weight: 500!important;
}
.fw_600 {
    font-weight: 600!important;
}
.fw_bold {
    font-weight: bold!important;
}
