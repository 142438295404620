@use "../base" as *;
@use "../mixin" as *;

.create_item_content {
    .inner_title {
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 20px;
        border-top: 1px solid $border_color_type2;
        padding-top: 15px;
        margin-top: 20px;
    }
}

.create_new_item_color {
    .color_filter_content {
        padding-top: 10px;
    }
    .color_item_list {
        display: flex;
        flex-wrap: wrap;
        .color_item_list_inner {
            width: 25%;
            padding: 5px;
            margin-bottom: 5px;
            .form_checkbox {
                border: 1px solid $border_color;
                padding: 5px;
                width: 100%;
            }
        }
    }
}

.item_img_upload_wrap {
    .image_show_wrap {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
        .inner {
            padding-left: 10px;
            padding-right: 10px;
            width: 10%;
            .inner_wrap {
                border: 1px solid #dfe0e1;
                position: relative;
                &:hover {
                    .remove_list {
                        opacity: 1;
                    }
                }
                .remove_list {
                    position: absolute;
                    right: 0;
                    top: 0;
                    opacity: 0;
                }
            }
        }
    }
    .item_img_upload {
        padding-top: 20px;
        label {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 10px;
            display: flex;
        }
        .image_upload {
            border: 1px solid rgba(145, 158, 171, 0.32);
            position: relative;
            border-radius: 5px;
            // background: #f5f5f5;
            input[type=file] {
                width: 100%;
                opacity: 0;
                height: 130px;
                position: relative;
                z-index: 9;
                cursor: pointer;
            }
            .image-uploads {
                @include d_flex_center;
                flex-direction: column;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                position: absolute;
            }
            h4 {
                font-size: 14px;
                font-weight: 500;
            }
        }
    }
}

.create_item_tab {
    position: relative;
    button {
        position: absolute;
        right: 0;
        top: 0;
    }
}

// ITEM VARIATION CONTENT
.item_variation_wrap {
    // position: relative;
    .add_varation_btn {
        position: absolute;
        right: 10px;
        top: -8px;
    }
    .item_variation_content {
        position: relative;
        padding-bottom: 25px;
        &:hover {
            .remove_variant {
                opacity: 1;
            }
            .add_option {
                opacity: 1;
            }
        }
        .remove_variant {
            position: absolute;
            right: 0;
            top: 0;
            opacity: 0;
        }
        .add_option {
            position: absolute;
            right: 15px;
            bottom: 10px;
            opacity: 1;
        }
        .variant_option_val {
            position: relative;
            margin-bottom: 10px;
            &:hover {
                .del_variant {
                    opacity: 1;
                }
            }
            .del_variant {
                position: absolute;
                right: 5px;
                top: 50%;
                transform: translateY(-50%);
                background: #fff;
                padding: 2px;
                opacity: 0;
                cursor: pointer;
            }
        }

    }
}
