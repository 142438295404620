.setting_content_wrap {
    display: table;
    width: 100%;
    table-layout: fixed;
    .setting_content {
        display: table-cell;
        max-width: 450px;
    }
    .setting_radio {
        display: table-cell;
    }
}

.account_setting_page {
    .account_setting_tab {
        display: flex;
        .tabs {
            width: 15%;
            border: none;
            margin-right: 10px;
            ul {
                flex-direction: column;
                li {
                    align-items: flex-start;
                    justify-content: flex-start;
                    margin-bottom: 5px;
                    margin-right: 0;
                    padding: 8px 15px;
                    &.active {
                        background: #000;
                        color: #fff;
                    }
                }
            }
        }
        .tab_content_wrapper {
            flex: 1;
            border-left: 1px solid rgb(223, 224, 225)!important;
            padding-left: 30px!important;
        }
    }
}