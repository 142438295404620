.mail_box {
    .mail_box_list {
        ul {
            display: flex;
            flex-direction: column;
            li {
                &:first-child {
                    a {
                        border-top: 1px solid rgba(100, 121, 143, 0.122);
                    }
                }
                a {
                    padding: 5px 0px;
                    -webkit-box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122);
                    box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122);
                    display: -webkit-box;
                    display: -webkit-flex;
                    display: flex;
                    position: relative;
                    &:hover {
                        .action_list {
                            opacity: 1!important;
                        }
                    }
                    .mail_box_list_inner {
                        display: flex;
                        width: 100%;
                        align-items: center;
                        .left_box {}
                        .mail_box_content {
                            flex-grow: 1;
                            p {
                                margin-bottom: 6px;
                                padding-top: 6px;
                            }
                            .attached_file {
                                display: flex;
                                gap: 5px;
                                .attached_file_inner {
                                    border: 1px solid rgb(222, 222, 221);
                                    border-radius: 25px;
                                    padding: 4px 8px;
                                    font-size: 12px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    span {
                                        padding: 0px 1px;
                                        img , i {
                                            margin-right: 4px;
                                        }
                                    }
                                    img {
                                        width: 14px;
                                        height: 14px;
                                    }
                                }
                            }
                        }
                        .right_box {
                            position: relative;
                            .action_list {
                                position: absolute;
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);
                                opacity: 0;
                                ul {
                                    display: flex;
                                    flex-direction: row;
                                    background: #fff;
                                    li {
                                        padding-left: 10px;
                                        font-size: 16px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .mail_box_details {
        .mail_box_details_head {
            h2 {
                display: flex;
                align-items: center;
                font-size: 24px;
                a {
                    padding-right: 10px;
                    font-size: 16px;
                    display: flex;
                }
                span {
                    display: flex;
                }
            }
            .mail_box_head_wrap {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 25px 0px;
                .left {
                    display: flex;
                    align-items: center;
                    .author {
                        background: #ccc;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 10px;
                        position: relative;
                        // border: ;
                        img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            object-fit: scale-down;
                        }
                    }
                    .content_details {
                        p {
                            margin-bottom: 3px;
                            b {
                                font-size: 16px;
                                margin-right: 5px;
                            }
                        }
                    }
                }
                .right {}
            }
        }
        .mail_details_content {
            padding: 40px 100px;
            h2 {
                margin-bottom: 20px;
            }

        }


        .reply_mail_content {
            padding-bottom: 30px;
            .reply_box_head_wrap {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 15px 50px;
                .left {
                    display: flex;
                    align-items: center;
                    .author {
                        background: #ccc;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 10px;
                        position: relative;
                        // border: ;
                        img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            object-fit: scale-down;
                        }
                    }
                    .content_details {
                        p {
                            margin-bottom: 3px;
                            b {
                                font-size: 16px;
                                margin-right: 5px;
                            }
                        }
                    }
                }
                .right {}
            }
            .mail_reply_details_content {
                padding: 10px 100px;
                h2 {
                    margin-bottom: 20px;
                }
            }
        }

        .reply_mail {
            ul {
                display: flex;
                padding-bottom: 20px;
                li {
                    display: flex;
                    margin-right: 10px;
                    button {
                        display: flex;
                        align-items: center;
                        padding: 5px 10px;
                        border-radius: 15px;
                        border: 1px solid #cccccc;
                        cursor: pointer;
                        svg {
                            margin-right: 8px;
                        }
                    }
                }
                
            }
            .reply_box {
                display: none;
                button {
                    // margin-top: 10px;
                }
            }
        }

        .attachment_preview_show {
            padding: 10px 0px;
            .prview_example {
                display: flex;
                gap: 15px;
                .preview_example_inner {
                    width: 150px;
                    height: 115px;
                    position: relative;
                    &:hover {
                        .preview_text_details {
                            opacity: 1!important;
                        }
                    }
                    .preview_text {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        background: rgba(18, 18, 18, .5);
                        color: #fff;
                        padding: 5px;
                    }
                    .preview_text_details {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgb(0, 0, 0);
                        color: #fff;
                        padding: 8px;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        opacity: 0;
                        span {
                            margin-right: 5px;
                            margin-top: 5px;
                            cursor: pointer;
                        }
                    }
                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                
            }
        }

    }
}