@use "../base" as *;
@use "../mixin" as *;
.table_responsive {
    display: block;
    width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}
.horizontal_scroller .table {
    margin-bottom: 0;
}
.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
    color: $color_black;
    border-collapse: collapse;
    border-top: 1px solid $border_color_type2;
}
.table_fixed {
    table-layout: fixed;
}
.table th {
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    padding: 12px 10px;
    // border-bottom: 1px solid $border_color_type2;
    white-space: nowrap;
    font-weight: 500;
    background: #000;
    color: #fff;
}
.table td {
    padding: 12px 10px;
    border-bottom: 1px solid $border_color_type2;
    font-size: 14px;
    word-wrap: break-word;
}

/*------------------------------
    BORDERED TABLE
------------------------------*/
.table_bordered {
    border: 1px solid $border_color_type2;
}

.table.table_bordered thead th {
    border-bottom: 1px solid $border_color_type2;
}

.table.table_bordered tr th,
.table.table_bordered tr td {
    border: 1px solid $border_color_type2;
}


/*------------------------------
    NO BORDERED TABLE
------------------------------*/
.table_bordered_none {
    border: 1px solid transparent;
}

.table.table_bordered_none thead th {
    border-bottom: 1px solid transparent;
}

.table.table_bordered_none tr th,
.table.table_bordered_none tr td {
    border: 1px solid transparent;
}



/*------------------------------
    HOVER TABLE
------------------------------*/
.table_hover tbody tr:hover {
    background-color: $dropdown_bg;
}

.table_hover tbody tr:nth-of-type(odd) {
    background-color: $main_bg;
}

/*------------------------------
    CENTER TABLE
------------------------------*/
.table.table_center tr th,
.table.table_center tr td {
    text-align: center;
}
.table.table_center thead tr th:first-child,
.table.table_center tbody tr td:first-child {
    text-align: left;
}
.table.table_center thead tr th:last-child,
.table.table_center tbody tr td:last-child {
    text-align: right;
}
.table.table_center tr td {
    text-align: center;
}


.data_table_arrow {
    position: relative;
    margin-left: 5px;
    display: inline-block;
    width: 10px; 
    height: 7.5px;
    background:transparent;
    cursor: pointer;
}
.data_table_arrow.arrow_down {
    position: relative;
    &::after {
        position: absolute;
        top: 1px;
        right: 0;
        width: 0; 
        height: 0; 
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #fff;
        content: "";
    }
}
.data_table_arrow.arrow_up {
    position: relative;
    &::after {
        position: absolute;
        top: 1px;
        right: 0;
        width: 0; 
        height: 0; 
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top : 5px solid #fff;
        content: "";
    }
}



/*------------------------------
    DATA TABLE 
------------------------------*/
table.dataTable, table.dataTable th, table.dataTable td {
    box-sizing: border-box!important;
}
table.dataTable {
    border-collapse: collapse!important;
}

table.dataTable tbody tr {
    background-color: transparent;
}
.dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_processing, .dataTables_wrapper .dataTables_paginate {
    color: $color_main;
}
.dataTables_wrapper .dataTables_processing {
    background: url('../../media/images/preloader.gif'), rgba(12, 26, 50, .6);
    z-index: 20;
    padding-top: 10px;
    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-position: center;
    height: 100%;
    font-size: 0;
    top: 0;
}
.dataTables_length{
    margin-bottom: 10px;
    select {
        display: inline-flex;
        margin: 0px 8px;
        height: 33px;
        padding: 5px 10px;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        color: $color_main;
        background-color: $nav_bg;
        border: 1px solid $border_color_type2!important;
        border-radius: 3px;
        position: relative;
        &:focus {
                color: $color_main;;
                background-color: $nav_bg;
                border: 1px solid $color_primary!important;
                outline: 0;
                box-shadow: none;
        }
    }
}

.dataTables_filter{
    margin-bottom: 10px;
    input {
        display: inline-flex;
        margin-left: 8px!important;
        height: 33px;
        padding: 5px 10px;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        color: $color_main;
        background-color: $nav_bg;
        border: 1px solid $border_color_type2!important;
        border-radius: 3px;
        position: relative;
        &:focus {
                color: $color_main;;
                background-color: $nav_bg;
                border: 1px solid $color_primary!important;
                outline: 0;
                box-shadow: none;
        }
    }
}

table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
    padding-right: 30px;
}
table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
    outline: none;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
    cursor: pointer;
    position: relative;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
    position: absolute;
    bottom: 0.9em;
    display: block;
    opacity: 0.3;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
    right: 1em;
    content: "\2191";
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    right: 0.5em;
    content: "\2193";
}
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
    opacity: 1;
}
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
    opacity: 0;
}


div.dataTables_wrapper div.dataTables_paginate {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    white-space: nowrap;
    justify-content: flex-end;
}
.dataTables_paginate.paging_simple_numbers .pagination .paginate_button a {
    padding: .5em 1em;
    border-radius: 2px;
    border: 0;
	margin: 0;
	min-width: 1.5em;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
    color: $color_main!important;
    border: 1px solid transparent!important;
    background: $dropdown_bg;
    box-shadow: none;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: $color_main!important;
    border: 1px solid transparent!important;
    background: $dropdown_bg;
    box-shadow: none;
 }
 .dataTables_wrapper .dataTables_paginate .paginate_button {
    color: $color_main!important;
    border: 1px solid transparent!important;
    background: $dropdown_bg;
    box-shadow: none;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    color: $color_main!important;
    border: 1px solid transparent!important;
    background: $hover_color3!important;
    box-shadow: none;
}
/*------------------------------
    FIXED SCROLL TABLE
------------------------------*/
.erp_fixed_table {}

.table_head_wrap {
    overflow-y: hidden!important;
}

.overflow_x_scroll {
    overflow-x: hidden!important;
    -webkit-overflow-scrolling: touch;
}
.overflow_y_scroll {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
.table_tbody_wrap {}
.table_tbody_wrap .mCSB_container_wrapper{
    margin-right: 0px;
    // margin-bottom: 30px;
}
.table_tbody_wrap .mCSB_container_wrapper > .mCSB_container {
    padding-right: 0;
}

/*------------------------------
    FIXED SCROLL TABLE 2 COL
------------------------------*/
.fixed_table_w_2col {}
.fixed_table_w_2col .ktwrap_left{
    width: 320px; 
    float: left;
    border-right: 1px solid #99BBE8;
}
.fixed_table_w_2col .ktwrap{
    width: calc(100% - 320px); 
    float: left;
}
.table_tbody_left_fixed{
    padding-bottom: 30px;
    overflow: hidden;
}
.fixed_table_w_2col .table_tbody_wrap .mCSB_container_wrapper > .mCSB_container {
    padding-right: 0;
    padding-bottom: 15px;
}
.fixed_table_w_2col .table_tbody_wrap .mCSB_container_wrapper {
    margin-right: 0px;
    margin-bottom: 0;
}
.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
    right: 0;
    background: #fff;
    height: 15px;
}


/*------------------------------
    STICKY TABLE
--------------------------------*/
.sticky_table_wrap {
    overflow: auto;
    height: 372px;
    position: relative;
    .bt_none {
        border-top: none;
        border-bottom: none;
    }
    .sticky_head th {
        position: sticky;
        //   background: #333;
        position: -webkit-sticky;
        position: sticky;
        top: -1px;
        z-index: 3;
    }
    .sticky_footer {
        position: sticky;
        //   background: #333;
        position: -webkit-sticky;
        position: sticky;
        bottom: -1px;
        background: #fff;
        z-index: 3;
    }
    .td_collapse_head {
        cursor: pointer;
        position: relative;
        width: 20px;
        height: 20px;
        &:after {
            font-family: "Font Awesome 5 Free";
            content: "\f0da";
            position: absolute;
            right: 0px;
            top: 0px;
            background: none;
            font-weight: bold;
            -webkit-transition: all .2s;
            transition: all .2s;
            font-size: 15px;
            width: 25px;
            height: 100%;
            @include d_flex_center;
        }
        &.open {
            &:after {
                content: "\f0d7";
                
            }
        }
    }
    .sticky_collpase_content {
        display: none;
    }
}




/*-----------------------------
    Volume Pricing
------------------------------*/

.volume_pricing table {
    width: 100%;
    table-layout: fixed;
    border-spacing: 0;
    border: 1px solid $border_color_type2;
    word-break: break-all;
}

.volume_pricing table tr th {
    // background-color: #fefefe;;
}

.volume_pricing table tr td,
.volume_pricing table tr th {
    font-size: 14px;
    font-weight: 400;
    padding: 7px 15px;
    border-right: 1px solid $border_color_type2;
    border-bottom: 1px solid $border_color_type2;
    line-height: initial;
}
.volume_day {
    display: flex;
    align-items: center;
    span {
        white-space: nowrap;
    }
}