.page_section_list_wrapper {
    .item_list_wrap {
        padding-top: 10px;
        .item_list_inner {
            padding-bottom: 20px;
            width: 12.5%;
            .item_list_inner_wrap {
                padding: 0;
                .text {
                    padding: 10px;
                    border-top: 1px solid rgb(240, 243, 247);
                    p {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: inherit;
                    }
                }
            }
        }
    }
}
.selected_product_wrap {
    .image_show_wrap {
        max-height: 330px;
        overflow: auto;
        padding-top: 10px;
        &::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar
        {
            width: .5px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb
        {
            background-color: #000000;
        }
        .inner {
            padding-bottom: 15px;
            width: 12.5%;
            .inner_wrap {
                .text {
                    border-top: 1px solid rgb(240, 243, 247);
                    p {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: inherit;
                    }
                }
            }
        }
    }
}