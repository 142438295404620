// @use "../layouts" as *;
// $font_Elsie:  'Elsie', cursive;
// $font_ShadowsLIght : 'Shadows Into Light', cursive;
// $font_Belleza : 'Belleza', sans-serif;
// $font_Spectral: 'Spectral', serif;
// $font_Martel: 'Martel', serif;
$font_Comfortaa: 'Comfortaa', cursive;
$font_Nunito: 'Nunito', sans-serif;
$font_Graphik: 'Graphik';
$font_Moderna: 'Moderna Sans';
$font_RobotoCondensed: 'Roboto Condensed', sans-serif;
 
// @use "./layouts/" as *;

$font_rubik: $font_RobotoCondensed;
$font_main : 'Roboto Condensed', sans-serif;
$themeColor : #9b7529;
$themeSubColor : #BABABA;


$color_white : #ffffff;
$color_black : #000;
$color_success : #34c38f;
$color_success_hover : #2ca67a;
$color_success_type2 : #2ca67a;

$color_warning : #ffc107;
$color_warning_hover : #ffca2c;

$color_green : #DEF7EC;
// $color_green : #1FB346;
$color_danger_hover : #FFDFDF;
$color_danger : #E24E4D;
$color_danger_type_2 : #E24E4D;
$color_secondary : #b7c1d1;
$color_secondary_hover : #98a6bd;
$color_info : #50a5f1;
$color_info_hover : #448ccd;
$color_primary : rgba(36, 36, 36, .90);
$color_primary_hover : rgba(36, 36, 36, .70);

$main_bg : #141212;
$nav_bg : #fff;
$dropdown_bg : #121212;
$inside_bg1 : #fff;
$color_main : #fff;
$hover_color : #061838;
$hover_color2 : #162f5b;
$hover_color3 : #172b4c;

$border_color :  rgb(223, 224, 225);
$border_color_type2 :  rgb(222, 222, 221);

// FONT SIZE
$font_12px : 12px;
$font_14px : 14px;
$font_16px : 16px;