
@use "../base/color" as *;
@use "../base/variable" as *;

* {
    margin: 0;
    padding: 0;
}
*,*::before,
*::after {
    box-sizing: border-box;
}

html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
}

body {
    // font-family: $font_rubik;
    font-family: $font_main;
    
    font-size: 14px;
    position: relative;
    color: $color_black;
    background: #fff;
    min-width: 1350px;
}

main {
    display: block;
}

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    border-color: $border_color;
}

pre {
    font-family: monospace, monospace;
    font-size: 1em;
}

a {
    background-color: transparent;
}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
}
abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
}

b,
strong {
    font-weight: 900;
}

code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

small {
    font-size: 90%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

img {
    border-style: none;
    vertical-align: middle;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}



button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
}

fieldset {
    padding: 0.35em 0.75em 0.625em;
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

textarea {
    overflow: auto;
}

[type="checkbox"],
[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    outline-offset: -2px;
    color: #b5b5c3;
}

[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

 ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

details {
    display: block;
}

summary {
    display: list-item;
}

template {
    display: none;
}

[hidden] {
    display: none;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

a,
a:hover,
a:focus {
    text-decoration: none;
    color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

p {
    margin: 0;
    margin-bottom: 10px;
    font-size: 14px;
}
:disabled {
    cursor: not-allowed;
    opacity: .7;
}
.disabled {
    cursor: not-allowed;
    opacity: .7;
}
.clearfix {
    position: relative;
}

.clearfix:after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
}
.img_circle {
    border-radius: 50%;
}
.overflow_hidden {
    overflow: hidden;
}
.cursor_pointer {
    cursor: pointer;
}
.custom_border_bottom {
    border-bottom: 1px solid $border_color_type2;
}
.border_none {
    border : none!important;
}
.border_right_none {
    border-right : none!important;
}
.text_right {
    text-align: right!important;
}
.td_underline {
    text-decoration: underline;
}
.text_nowrap {
    white-space: nowrap;
}
// html {
//     --scrollbarBG: $main_bg;
//     --thumbBG: #303e56;
//   }
// body::-webkit-scrollbar {
//     width: 17px;
// }
// body {
//     scrollbar-width: thin;
//     scrollbar-color: var(--thumbBG) var(--scrollbarBG);
// }
// body::-webkit-scrollbar-track {
//     background: var(--scrollbarBG);
// }
// body::-webkit-scrollbar-thumb {
//     background-color: var(--thumbBG) ;
//     border-radius: 2px;
//     border: 3px solid var(--scrollbarBG);
// }

.sticky_table_wrap {
    scrollbar-width: thin;
    scrollbar-color: #12213c;
}
.sticky_table_wrap::-webkit-scrollbar {
    width: 17px;
}
.sticky_table_wrap::-webkit-scrollbar-track {
    background: #12213c;
}
.sticky_table_wrap::-webkit-scrollbar-thumb {
    background-color:#303e56 ;
    border-radius: 0px;
    border: 3px solid #303e56;
}

#wrap {
    &.has_left_menu {
        #left_nav {
            top: 62px;
            height: calc(100% - 62px);
            &.vendor_left_nav {
                padding-left: 0px;
                padding-right: 0px;
                padding-top: 0px;
                .left_nav_list {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }
        #content_wrap {
            padding-left: 280px;
            padding-top: 100px;
            padding-right: 10px;
        }
    }
}

.box_shadow_none {
    box-shadow: none!important;
}
.line_height_21p {
    line-height: 21px;
}

.font_weight_normal {
    font-weight: normal;
}

.p_right_center {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    padding: 4px;
    background: #fff;
}