@use "../base" as *;
@use "../mixin" as *;


/*----------------------------
    TOP NOTIFICATION
-----------------------------*/

.top_notification {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    .left  {
        display: flex;
        align-items: center;
        align-self: stretch;
        padding: 15px 15px 15px 30px;
        background: #418DFD;
        width: 72%;
        .icon {
            width: 60px;
            height: 60px;
            background: #000;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 30px;
            img {
                width: 18px;
            }
        }
        .text {
            h2 {
                font-size: 14.69px;
                font-weight: 500;
                padding-bottom: 8px;
            }
            p {
                font-size: 13px;
                font-weight: 400;
                margin-bottom: 0;
            }
        }
    }
    .right  {
        padding: 15px 15px 15px 45px;
        background: #000;
        flex: 1;
        h2 {
            font-size: 14.69px;
            font-weight: 500;
            padding-bottom: 8px;
        }
        p {
            font-size: 13px;
            font-weight: 400;
        }
    }
}


/*------------------------------
    DASHBOARD MINI STATS
------------------------------*/
.mini-stats {
    background: #F6F6F6;
    border: 1px solid rgba(222,222,221,.8);
    margin: 20px 0px;
    padding: 0px 10px;
    .card_heading {
        font-size: 18px;
        font-weight: 600;
        color: #111827;
        border-bottom: 1px solid rgba(222,222,221,1);
        padding: 10px 0px;
    }
    .card_body {
        // padding: 10px 15px;
        .mini-stats-content {
            display: flex;
            align-items: center;
            justify-content: center;
            .inner {
                flex: 1;
                @include d_flex_center;
                flex-direction: column;
                &:first-child {
                    border-right: 1px solid rgba(222,222,221,1);
                }
                h2 {
                    font-size: 22px;
                    font-weight: 600;
                    color: #111827;
                    padding-bottom: 5px;
                }
                p {
                    font-size: 13px;
                    font-weight: 400;
                    color: #818181;
                    margin-bottom: 0;
                }
            }
        }
    }
}

/*------------------------------
    BEST ITEMS
------------------------------*/
.best_items {
    .card_heading {
        @include d_flex_btwn;
        padding: 20px 15px 10px;
        span {
            font-size: 20px;
            font-weight: 600;
            color: #111827;
            &:last-child {
                font-size: 12.6px;
                color: #818181;
                font-weight: 400;
            }
        }
    }
    .best_item_content {
        display: flex;
        margin-left: -10px;
        margin-right: -10px;
        .inner {
            width: 20%;
            padding-left: 10px;
            padding-right: 10px;
            .inner_wrap {
                .img {
                    position: relative;
                    .num {
                        width: 24px;
                        height: 24px;
                        position: absolute;
                        top: 0;
                        right: 5px;
                        background: #000;
                        @include d_flex_center;
                        font-size: 14px;
                        color: #fff;
                        font-weight: 500;
                    }
                }
                .text {
                    padding: 15px 0px;
                    h2 {
                        font-size: 14.69px;
                        font-weight: 600;
                        padding-bottom: 8px;
                        span {
                            font-size: 13px;
                            font-weight: 400;
                            color: #818181;
                        }
                    }
                    p {
                        font-size: 13px;
                        font-weight: 400;
                        color: #818181;
                        margin-bottom: 5px;
                        &.border_top {
                            padding-top: 10px;
                            border-top: 1px solid rgb(222, 222, 221);
                        }
                    }
                }
            }
        }
    }
}

/*------------------------------
    RECENT ORDERS
------------------------------*/
.recent_orders {
    .card_heading {
        @include d_flex_btwn;
        padding: 20px 15px 10px;
        span {
            font-size: 20px;
            font-weight: 600;
            color: #111827;
            &:last-child {
                font-size: 12.6px;
                color: #818181;
                font-weight: 400;
            }
        }
    }
    .recent_orders_content {
        table {
            border-top: none;
            tr {
                &:first-child {
                    td {
                        border-top: none;
                    }
                }
                &:last-child {
                    td {
                        border-bottom: none;
                    }
                }
                td {
                    font-size: 14.4px;
                    font-weight: bold;
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
        .name {
            font-weight: 600;
            span {
                font-size: 11px;
                font-weight: 400;
            }
        }
    }
}


/*------------------------------
    DASHBOARD SELLING STATS
------------------------------*/
.dashboard_selling_stats {
    .card_heading {
        background: #000;
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        line-height: 26px;
    }
    .dashboard_selling_stats_content {
        display : flex;
        flex-wrap: wrap;
        height: 100%;
        align-items: center;
        position: relative;
        &:after {
            position: absolute;
            content: "";
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            background: #DEDEDD;
            z-index: 2;
        }
        .inner {
            width: 33.3333%;
            padding: 20px 10px;
            .inner_wrap {
                display: flex;
                flex-direction: column;
                border-right: 1px solid #DEDEDD;
                padding: 15px 25px;
                h2 {
                    font-size: 14.69px;
                    font-weight: 600;
                    padding-bottom: 8px;
                }
                h1 {
                    font-size: 22px;
                    font-weight: 600;
                    color: #111827;
                    padding-bottom: 5px;
                }
                p {
                    font-size: 13px;
                    font-weight: 400;
                    margin-bottom: 0;
                    color: #818181;
                    span {
                        color: #000;
                    }
                }
                .down {
                    color: rgb(220, 53, 69);
                    font-size: 12px;
                }
                .up {
                    color: rgb(16, 183, 89);
                    font-size: 12px;
                }
            }
        }
    }
}


.dashboard_title {
    padding: 20px 0px 15px;
    h2 {
        font-size: 20px;
        font-weight: 400;
        padding: 10px 13px;
        // background: #fff;
        // border-left: 3px solid #e2e2e2;
        // box-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
        // font-family: $font_Nunito;
    }   
}

.dashboard_content {
    border: 1px solid $border_color;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    position: relative;
    border-radius: 3px;
    h3 {
        font-size: 22px;
        padding-bottom: 7px;
        font-family: $font_Nunito;
        span {
            font-size: 14px;
            &.up {
                color: rgb(16, 183, 89);
            }
            &.down {
                color: rgb(220, 53, 69);
            }
        }
    }
    p {
        font-size: 13px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        font-weight: 600;
        color: $color_black;
    }
    .icons {
        height: 40px;
        width: 40px;
        position: absolute;
        bottom : 10px;
        border-radius: 5px;
        right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
            color: 16px;
        }
        &.color_1 {
            background: rgba(56,202,179,0.2);
            color: #38cab3;
        }
        &.color_2 {
            background: #4ec2f033;
            color: #4ec2f0;
        }
        &.color_3 {
            background: #f74f7533;
            color: #f74f75;
        }
        &.color_4 {
            background: #ffbd5a33;
            color: rgba(255,193,7,1);
        }
        
    }

    .progress-bar {
        grid-column-start: 2;
        height: 45px;
        width: 100%;
        padding: 12px 0;
        .progress-track {
            background: rgb(227, 231, 237);
        }
        .progress-fill {
            background: rgba(42, 39, 39, 0.9019607843);
            height: 7px;
            width: 50%;
            color: #fff;
            text-align: center;
            font-size: 0px;
            line-height: 20px;
        }
    }    
}

.circle_svg {
    padding: 20px;
    border: 1px solid $border_color;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 15px;
    margin-top: 15px;
    p {}
    h2 {
        padding-bottom: 10px;
        font-family: $font_Nunito;
    }
    .bottom_text {
        padding-top: 15px;
        p {
            margin-bottom: 0;
            color: rgb(220, 53, 69);
            &.up {
                color: rgb(16, 183, 89);
                
            }
        }
    }
    .circle_svg_inner {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .round {
            transform: rotate(-90deg);
            transition: all 1s ease-in-out;
            fill: none;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-dasharray: 0 999;
            &.color_1 {
                stroke: $color_black;
            }
            &.color_2 {
                stroke: #09ad95;
            }
            &.color_3 {
                stroke: #ffc107;
            }
            &.color_4 {
                stroke: #dc3545;
            }
            &.color_5 {
                stroke: #38cab3;
            }
            &.color_6 {
                stroke: #4ec2f0;
            }
            &.color_7 {
                stroke: #f74f75;
            }
            &.color_8 {
                stroke: rgba(255,193,7,1);
            }
        }
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50% , -50%);
            font-size: 25px;
        }
    }
}

.top_sallers_table {
    margin-top: 15px;
    border: 1px solid $border_color;
    box-shadow: none;
    .card_heading {
        font-weight: 600;
        padding: 15px;
        border: none;
        padding-bottom: 0px;
    }
    .img_wrap {
        display: flex;
        align-items: center;
        .img {
            border-radius: 50%;
            width: 24.5px;
            height: 24.5px;
            overflow: hidden;
            display: inline-flex;
            position: relative;
            margin-right: 10px;
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
                display: flex;
            }
        }
    }
    .up {
        color: rgb(16, 183, 89);
    }
    .down {
        color: rgb(220, 53, 69);
    }
}

