
@use "../mixin" as *;
@use "../base" as *;
.form_global {
    display: block;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: $color_black;
    position: relative;
    border: 1px solid $border_color_type2;
    height: 40px;
    padding: 5px 16px;
    background: transparent;
    cursor: text;
    // background: #000;
    &:focus {
        color: $color_black;
        // background-color: $nav_bg;
        border: 1px solid $border_color_type2;
        outline: 0;
        box-shadow: none;
    }
    @include belowIpadpro {
        font-size: 16px;
    }
}
.form_global.text_white {
    color: #fff;
}
textarea.form_global {
    height: auto;
    resize: vertical;
}
.form_row {
    margin-bottom: 15px;
    position: relative;
    label {
        margin-bottom: 8px;
        display: flex;
    }
    .form_input {
        display: flex;
    }
}
.form_row_inline {
    margin-bottom: 15px;
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    label {
        width: 120px;
        padding-right: 10px;   
    }
    .form_input {
        width: calc(100% - 120px);
        display: flex;
    }
    small {
        max-width:100%;
        flex: 0 0 100%;
        padding-left: 120px;
    }
}
.has_error {
    label {
        color: #E24E4D;
    }
    .form_input {}    
    .form_global {
        border: 1px solid #E24E4D;
        color: #E24E4D;
        &:focus {
            border: 1px solid #E24E4D;
        }
    }
    small {
        position: absolute;
        left: 0;
        top: 100%;
        color: #E24E4D;
    }
}


.has_error .form_checkbox label {
    color : #E24E4D;
}

.has_error .form_radio label {
    color : #E24E4D;
}
.required {
    position: relative;
}
.required:after {
    content: '*';
    margin-left: 3px;
    color: #E24E4D;
    font-size: 12px;
}

.search_input {
    position: relative;
    width: 100%;
    input {
        padding-left: 40px;
    }
    &:before {
        font-family: "LineIcons";
        content: "\ea17";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 7px;
        display: inline-block;
        color: $color_black;
        line-height: 14px;
        font-weight: 400;
        pointer-events: none;
        z-index: 2;
        font-size: 20px;
    }
}

/*----------------------------
    CUSTOM CHECKBOX
-----------------------------*/

.form_checkbox {
    display: inline-block;
    position: relative;
    padding: 4px 0px;
}

.form_checkbox [type="checkbox"] {
    position: absolute;
    left: -9999px;
}

.form_checkbox label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    margin-bottom: 0;
    line-height: initial;
    padding-right: 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    min-height: 21px;
    color: $color_black;
}
.form_checkbox [type="checkbox"] + label:before, 
.form_checkbox .form_checkbox[type="checkbox"]:not(.filled-in) + label:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0;
    width: 18px;
    height: 18px;
    z-index: 0;
    border: 1px solid $color_black;
    border-radius: 1px;
    margin-top: 2px;
    transition: .2s;
}
.form_checkbox [type="checkbox"]:not(.filled-in) + label:after {
    border: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.form_checkbox [type="checkbox"]:checked + label:before {
    top: -4px;
    left: -2px;
    width: 12px;
    height: 18px;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid $color_black;
    border-bottom: 1px solid $color_black;
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
}



// $brand: #0080d3;
// $grey-25: #e6e6e6;
// $grey-5: #fcfcfc;

// .custom_checkbox {
//     .custom_checkbox_input {
//         border: 0; 
//         clip: rect(0 0 0 0); 
//         height: 1px; margin: -1px; 
//         overflow: hidden; 
//         padding: 0; 
//         position: absolute; 
//         width: 1px;
//     }
//     input:checked + label > svg {
//         // Firefox doesn't render svg's that is loading with the use tag if its been set to display: none and then toggled to display: block, so you have to use tricks like this to get it to render again:
//         height: 24px;
//         animation: draw-checkbox ease-in-out 0.2s forwards;
//     }
//     label:active::after {
//         background-color: $grey-25;
//     }
  
//     label {
//         color: $brand;
//         line-height: 40px;
//         cursor: pointer;
//         position: relative;
//         svg {
//             stroke: $brand;
//             stroke-width: 2px;
//             height: 0; //Firefox fix
//             width: 24px;
//             position: absolute;
//             left: -48px;
//             top: -4px;
//             stroke-dasharray: 33; //Firefox fix
//         }
//         &:after {
//             content: "";
//             height: 40px;
//             width: 40px;
//             margin-right: 1rem;
//             float: left;
//             border: 2px solid $brand;
//             border-radius: 3px;
//             transition: 0.15s all ease-out;      
//         }
//     }

// }

// @keyframes draw-checkbox {
//   0% {
//     stroke-dashoffset: 33;
//   }
//   100% {
//     stroke-dashoffset: 0;
//   }
// }




/*----------------------------
    CUSTOM RADIO
-----------------------------*/
.form_radio {
    display: inline-block;
    position: relative;
    padding: 4px 0px;
}

.form_radio [type="radio"] {
    position: absolute;
    left: -9999px;
}
.form_radio label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    margin-bottom: 0;
    line-height: initial;
    padding-right: 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    min-height: 17px;
    color: $color_black;
}

.form_radio [type="radio"] + label:before {
    border: 1px solid $color_black;
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 17px;
    height: 17px;
    z-index: 0;
    transition: .28s ease;
    border-radius: 50%;
}
.form_radio [type="radio"]:not(:checked) + label:before {
    border: 1px solid $color_black;
}
.form_radio [type="radio"]:checked + label:before {
    border: 1px solid $color_black;
}
.form_radio [type="radio"] + label:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 3px;
    // transform: translate(-50% , -50%);
    width: 11px;
    height: 11px;
    z-index: 0;
    transition: .28s ease;
    border-radius: 50%;
}

.form_radio [type=radio]:checked + label:after {
    border: 1px solid $color_black;
    background-color: $color_black;
    transition: .28s ease;
    width: 11px;
    height: 11px;
}




/*-------------------------------
   SELECT 2 FORM PLUGIN
--------------------------------*/
.select2-selection.select2-selection--multiple {
    outline: 0!important;
    border: 1px solid $border_color_type2!important;
    border-radius: 0!important;
    min-height: 33px!important;
}
.select2-selection__choice {
    margin-right: 5px !important;
    margin-top: 4px !important;
    padding: 0 5px !important;
    line-height: 22px !important;
    background-color: #fff!important;
    border: 1px solid $border_color_type2!important;
    border-radius: 2px;

}
.select2-results__option {
    padding: 0px 6px!important;
    user-select: none;
    -webkit-user-select: none;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    background-color: transparent;
    border: none;
    border-right: 1px solid $border_color_type2;
    border-top-left-radius: 2px;
    border-bottom-left-radius:2px;
    color: $color_black;
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    padding: 0 4px;
    &:hover {
        background-color: transparent!important;
        color: $color_black;
    }
}
.select2 {
    display: block!important;
    width: 100%!important;
    min-height: 33px!important;
    padding: 0!important;
    font-size: 14px!important;
    font-weight: 400!important;
    line-height: 1.5!important;
    color: #b5b5c3!important;
    background-color: #fff!important;
    // border: 1px solid rgba(255, 255, 255, 0.12)!important;
    border-radius: 3px!important;
    .selection {
        width: 100%!important;
        min-height: 33px!important;  
    }
    .select2-selection {
        display: block!important;
        width: 100%!important;
        min-height: 33px!important;
        padding: 0px 10px!important;
        font-size: 14px!important;
        font-weight: 400!important;
        line-height: 1.5!important;
        color: #b5b5c3!important;
        background-color: #fff!important;
        border: 1px solid $border_color_type2!important;
        border-radius: 3px!important;
        position: relative!important;
        &:focus {
            color: $color_black;;
            background-color: $nav_bg;
            border: 1px solid #000;
            outline: 0;
            box-shadow: none;
        }
        .select2-selection__rendered {
            color: $color_black!important;
            line-height: 27px!important;
            padding: 0!important;
        }
    }
    // &.select2-container--open {
    //     width: auto!important;
    // }
}

element.style {
    width: 388.25px;
}
.select2-container--open .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.select2-container--open .select2-dropdown {
    left: 0;
}
.select2-dropdown {
    background-color: transparent!important;
    border: 1px solid $border_color_type2;
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: -100000px;
    width: 100%;
    z-index: 1051;
    background-color: #fff!important;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid $border_color_type2;
    background: transparent;
    background-color: #fff!important;
    color: $color_black;
    &:focus {
        color: $color_black;;
        background-color: $nav_bg;
        border: 1px solid #000;
        outline: 0;
        box-shadow: none;
    }
}
.select2-container--default .select2-results__option--selected {
    background-color: #fff!important;
}
/*----------------------------
    DATE PICKER STYLE
-----------------------------*/

.datepicker_global {
    position: relative;
    padding-right: 27px;
}

.datepicker_global:after {
    font-family: "Font Awesome 5 Free";
    content: "\f073";
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
    font-size: 15px;
    background: $inside_bg1;
    z-index: 5;
    padding: 8px;
    height: 100%;
    border-radius: 0px 3px 3px 0px;
    border: 1px solid $border_color;
}
.datepicker_global.small:after {
    font-size: 12px;
}
.ui-datepicker , .ui-datepicker-inline{
    width: 300px;
    font-family: "Poppins", sans-serif;
}
#ui-datepicker-div {
    border: 0px solid #c5c5c5;
    box-shadow: 0 0 50px 0 rgba(82,63,105,.15);
    padding: 5px;
    border-radius: 4px;
    // background: $dropdown_bg;
    z-index: 2001!important;

}
#ui-datepicker-div .ui-widget-header {
    border: 0px solid #dddddd;
    // background: $dropdown_bg;
    background: transparent;
    color: $color_black;
    font-weight: bold;
}
#ui-datepicker-div .ui-datepicker-prev.ui-state-hover,
#ui-datepicker-div .ui-datepicker-next.ui-state-hover {
    border: 0px solid #cccccc;
    // background: $dropdown_bg;
    color: $color_black;
}
#ui-datepicker-div .ui-datepicker-prev {
    width: 20px;
    height: 20px;
    left: 2px;
    border: 0px solid #cccccc;
    cursor: pointer;
    top: 4px;
    color: $color_black;
}
#ui-datepicker-div .ui-datepicker-prev:after {
    font-family: 'boxicons'!important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% ,-50%);
    background: none;
    font-weight: bold;
    content: "\e9af";
    -webkit-transition: all .3s;
    transition: all .3s;
    font-size: 22px;
}
#ui-datepicker-div .ui-datepicker-next {
    width: 20px;
    height: 20px;
    right: 2px;
    border: 0px solid #cccccc;
    cursor: pointer;
    top: 4px;
    color: $color_black;
}
#ui-datepicker-div .ui-datepicker-next:after {
    font-family: 'boxicons'!important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% ,-50%);
    background: none;
    font-weight: bold;
    content: "\e9b2";
    -webkit-transition: all .3s;
    transition: all .3s;
    font-size: 22px;
}
#ui-datepicker-div .ui-datepicker-next {}
#ui-datepicker-div .ui-widget-header .ui-icon {
    background-image: none;
}
#ui-datepicker-div.ui-datepicker th {
    color: $color_black;
}
#ui-datepicker-div.ui-datepicker td.ui-datepicker-current-day {
    background: #323232!important;
}
#ui-datepicker-div.ui-datepicker td {
    border: 0;
    padding: 1px;
    width: 30px;
    height: 30px;
    text-align: center;
    a.ui-state-active,
    a,
    span {
        border : none!important;
        background: transparent!important;
        text-align: center;
        color: $color_black;
    }
    &:hover {
        background: #f9f9f9;
    }
}


.ui-datepicker-inline {
    border: 0px solid #c5c5c5;
    box-shadow: 0 0 50px 0 rgba(82,63,105,.15);
    padding: 5px;
    border-radius: 4px;
    background: $dropdown_bg;

}
.ui-datepicker-inline .ui-widget-header {
    border: 0px solid #dddddd;
    background: $color_white;
    color: $color_black;
    font-weight: bold;
}
.ui-datepicker-inline .ui-datepicker-prev.ui-state-hover,
.ui-datepicker-inline .ui-datepicker-next.ui-state-hover {
    border: 0px solid #cccccc;
	background: $color_white;
}
.ui-datepicker-inline .ui-datepicker-prev {
    width: 20px;
    height: 20px;
    left: 2px;
    border: 0px solid #cccccc;
    cursor: pointer;
    top: 2px;
        padding: 0;
}
.ui-datepicker-inline .ui-datepicker-prev:after {
    font-family: 'LineIcons' !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% ,-50%);
    background: none;
    font-weight: bold;
    content: "\ea44";
    -webkit-transition: all .3s;
    transition: all .3s;
    font-size: 15px;
}
.ui-datepicker-inline .ui-datepicker-next {
    width: 20px;
    height: 20px;
    right: 2px;
    border: 0px solid #cccccc;
    cursor: pointer;
    top: 2px;
    padding: 0;
}
.ui-datepicker-inline .ui-datepicker-next:after {
    font-family: 'LineIcons' !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% ,-50%);
    background: none;
    font-weight: bold;
    content: "\ea46";
    -webkit-transition: all .3s;
    transition: all .3s;
    font-size: 15px;
}
.ui-datepicker-inline .ui-datepicker-next {}
.ui-datepicker-inline .ui-widget-header .ui-icon {
    background-image: none;
}
.ui-datepicker-inline.ui-datepicker td {
    border: 0;
    padding: 1px;
    width: 20px;
    height: 20px;
    text-align: center;
    a.ui-state-active,
    a,
    span {
        border : none!important;
        background: transparent!important;
        text-align: center;
        color: $color_black;
        padding: 0!important;
    }
    &:hover {
        background: $main_bg;
    }
}




/*----------------------------
    PLACEHOLDER FONT SIZE
-----------------------------*/
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color_black;
    font-size: 14px;
}
::-moz-placeholder { /* Firefox 19+ */
    color: $color_black;
    font-size: 14px;
}
::-ms-input-placeholder { /* IE 10+ */
    color: $color_black;
    font-size: 14px;
}
::-moz-placeholder { /* Firefox 18- */
    color: $color_black;
    font-size: 14px;
}


.text_white::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #fff;
    font-size: 14px;
}
.text_white::-moz-placeholder { /* Firefox 19+ */
    color: #fff;
    font-size: 14px;
}
.text_white::-ms-input-placeholder { /* IE 10+ */
    color: #fff;
    font-size: 14px;
}
.text_white::-moz-placeholder { /* Firefox 18- */
    color: #fff;
    font-size: 14px;
}



/*----------------------------
   LEFT FIXED $
-----------------------------*/
.plc_fixed_left {
    position: relative;
    display: inline-block;
}

.plc_fixed_left:before {
    content: "$";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 3px;
    display: inline-block;
    color: #999;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    pointer-events: none;
}
/*----------------------------
   LEFT FIXED SEARCH
-----------------------------*/
.plc_fixed_left_search {
    position: relative;
    display: inline-block;
}

.plc_fixed_left_search input {
    padding-left: 20px;
}

.plc_fixed_left_search:before {
    font-family: "Font Awesome 5 Free";
    content: "\f002";
    font-weight: 900;
    position: absolute;
    top: 4px;
    left: 3px;
    display: inline-block;
    color: #999;
    font-size: 14px;
    line-height: 14px;
    pointer-events: none;
}
/*----------------------------
   RIGHT FIXED SEARCH
-----------------------------*/
.plc_fixed_right_search {
    position: relative;
    display: inline-block;
}

.plc_fixed_right_search input {
    padding-right: 20px;
}

.plc_fixed_right_search:before {
    font-family: "Font Awesome 5 Free";
    content: "\f002";
    font-weight: 900;
    position: absolute;
    top: 4px;
    right: 5.5px;
    display: inline-block;
    color: #16325c;
    font-size: 14px;
    line-height: 14px;
    pointer-events: none;
}

/*----------------------------
    NUMBER ARROW NONE
-----------------------------*/

.input_number input {
    text-align: right;
    appearance: none;
}

.input_number input::-webkit-outer-spin-button,
.input_number input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.input_number input[type=number] {
    -moz-appearance: textfield;
}


.input_inline {
    display: table;
    width: 100%;
}

.input_inline div {
    display: table-cell;
    display: inline-block;
}
