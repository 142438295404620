@use "../base" as *;
@use "../mixin" as *;

.item_color_main_wrap {
    .add_new_color {
        border: 1px solid $border_color;
        padding: 15px;
        display: none;
    }
    .item_color_list_wrap {
        .item_color_wrap {
            display: flex;
            flex-wrap: wrap;
            padding: 25px 0px;
            margin-left: -5px;
            margin-right: -5px;
            .item_color_inner {
                width: 12.5%;
                padding-left: 5px;
                padding-right: 5px;
                padding-bottom: 10px;
                .item_color_inner_wrap {
                    padding: 10px;
                    display: flex;
                    border: 1px solid $border_color_type2;
                    .item_color_img {
                        img {
                            width: 35px;
                        }
                    }
                    .item_color_text {
                        padding-left: 5px;
                        p {
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }
    }
}